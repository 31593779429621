<template>
  <div class="app-top-nav">
    <div class="w">
      <nav class="navbar">
        <ul :class="{ 'login-in': (isLoggedIn) }">
          <li v-for="(item, index) in navItems" :key="index">
            <Router-Link :to="item.path" class="nav-item" :class="{ active: currentRoute === item.path }">
              {{ item.name }}
            </Router-Link>
          </li>
          <li>
            <a href="mailto:support@planetlinkinc.com?subject=Customer%20Service">Contact</a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useUserStore } from "@/store/user";
import { computed } from "vue";
import { useRoute } from "vue-router";

const userStore = useUserStore();
const userEmail = computed(() => userStore.user.email);
const route = useRoute();
const isLoggedIn = computed(() => !!userEmail.value);

const navItems = [
  { name: "Home", path: "/" },
  { name: "Terms of service", path: "/terms" },
  { name: "Privacy policy", path: "/privacy" },
  { name: "IOS Privacy Policy", path: "/iosprivacy" },
];

const currentRoute = computed(() => route.path);
</script>

<style lang="less" scoped>
.app-top-nav {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  height: 60px;
  line-height: 60px;
  display: flex;
  overflow-y: hidden;
  overflow-x: auto;
  white-space: nowrap;
  text-align: center;
  background-color: #150f25;
  z-index: 3;

  .w {
    width: 100%;
  }

  .navbar {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  ul {
    display: inline-block;
    margin: 0;
    list-style: none;
    white-space: nowrap;
    margin-left: -1vw;
  }

  li {
    display: inline-block;
    flex-shrink: 0;
    text-align: center;

    a {
      display: block;
      font-family: PingFang SC;
      padding: 0 5vw 1vw 6vw;
      color: #d7d6e3;
      font-weight: 550;
      font-size: 3.5vw;
      transition: color 0.3s;

      &:hover,
      &.active {
        background: repeating-linear-gradient(to right, #fad62c, #e664ff, #4faeff);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
}

.login-in {
  padding: 0 10vw 0 0;
}

// 隐藏滚动条
.app-top-nav::-webkit-scrollbar {
  display: none;
}

.interactive-link {
  position: fixed;
  right: 0;
  top: 0;
  width: 20vw;
  height: 7vh;
  background: linear-gradient(to right, #1f153544, #1f1535a1, #1f1535);
  z-index: 3;
}

.avatar-circle {
  margin-top: 2vh;
  margin-left: 8vw;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8vw;
  height: 8vw;
  background-color: #4caf50;
  color: white;
  font-size: 5vw;
  border-radius: 50%;
  border: 2px solid white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  position: relative;
  overflow: hidden;
  z-index: 4;
}
</style>
