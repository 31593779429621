import { defineComponent as _defineComponent } from 'vue'
import { onMounted, onBeforeUnmount, ref, computed } from "vue";
import "@/assets/style/base_mobile.less";
import { useUserStore } from "@/store/user";
import urlUtils from "@/utils/urlUtils";


export default /*@__PURE__*/_defineComponent({
  __name: 'Home_mobile',
  setup(__props, { expose: __expose }) {
  __expose();

const showPopup = ref(false);

const userStore = useUserStore();

const userEmail = computed(() => userStore.user.email);
const isLoggedIn = computed(() => !!userEmail.value);
const isVip = computed(() => !!userStore.user.isVip);
const connectionMode = computed(() => (isVip.value ? "vip" : "free"));
const validUntil = computed(() => userStore.user.endDate || "");

const downloadUrl = ref(urlUtils.getDownloadUrl());

function handleScroll() {
  if (window.scrollY <= 0) {
    window.scrollTo(0, 0);
  }
}

const userInitial = computed(() => (userEmail.value ? userEmail.value.charAt(0).toUpperCase() : ""));

function togglePopup() {
  showPopup.value = !showPopup.value;
}

onMounted(() => {
  window.addEventListener("scroll", handleScroll);
});

onBeforeUnmount(() => {
  window.removeEventListener("scroll", handleScroll);
});

const __returned__ = { showPopup, userStore, userEmail, isLoggedIn, isVip, connectionMode, validUntil, downloadUrl, handleScroll, userInitial, togglePopup }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})