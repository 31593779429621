import { defineComponent as _defineComponent } from 'vue'
import { computed, ref } from "vue";
import { isPasswordStrong } from "@/utils/index";
import { firebaseService } from "@/services/firebaseService";
import { useRouter } from "vue-router";
import { openErrModal } from "@/utils/ui";
import RouterUtils from "@/utils/routerUtils";
import { useUserStore } from "@/store/user";


export default /*@__PURE__*/_defineComponent({
  __name: 'SetPassWord',
  setup(__props, { expose: __expose }) {
  __expose();

const router = useRouter();
const userStore = useUserStore();

const loading = ref<boolean>(false);
const open = ref<boolean>(false);
const password = ref("");
const confirmPassword = ref("");
const passwordError = ref("");
const canLogin = computed(() => passwordError.value === "");
const routerUtils = new RouterUtils(router);

const handleOk = () => {
  loading.value = true;
  setTimeout(() => {
    loading.value = false;
    open.value = false;
  }, 2000);
};

const handleCancel = () => {
  open.value = false;
};

const jumpToLoginPage = () => {
  routerUtils.jumpToLoginPage();
};

const checkPassword = () => {
  if (!isPasswordStrong(password.value)) {
    passwordError.value = "Password must be at least 8 characters long and contain two of the following: letters, numbers, special characters.";
  } else if (password.value !== confirmPassword.value) {
    passwordError.value = "Passwords do not match.";
  } else {
    passwordError.value = "";
  }
};

const confirmLogin = async () => {
  const userEmail = userStore.user.email;
  if (!userEmail) {
    openErrModal("email invalid");
  }
  const result = await firebaseService.setPassword(userEmail, confirmPassword.value);
  if (result.success) {
    // 跳转到首页
    routerUtils.jumpToMainPage();
  }
};

const __returned__ = { router, userStore, loading, open, password, confirmPassword, passwordError, canLogin, routerUtils, handleOk, handleCancel, jumpToLoginPage, checkPassword, confirmLogin }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})