<template>
  <div class="setPassword_wrap">
    <div class="form-container">
      <span class="form-title">REGISTER</span><br />
      <div class="step-indicator">
        <div class="step-one">
          <img src="@/assets/images/sign/step-one.png" alt="" />
          <span class="step-number">1</span>
        </div>
        <hr class="step-line" />
        <div class="step-two">
          <img src="@/assets/images/sign/step-two-bright.png" alt="" />
          <span class="step-number">2</span>
        </div>
      </div>
      <p class="step-description">Step 2 : Set password</p>
      <p class="password-hint">Any character with 6 or more digits</p>
      <a-input-password v-model:value="password" @focusout="checkPassword" placeholder="Password" class="password" />
      <!-- 密码框 -->
      <a-input-password v-model:value="confirmPassword" @focusout="checkPassword" placeholder="Confirm Password"
        class="password" />
      <p v-if="passwordError" class="error-message">{{ passwordError }}</p>
    </div>
    <div class="email-send-button">
      <div class="confirmation-button" @click="handleConfirmLogin" @keydown.enter="handleConfirmLogin"
        @keydown.space.prevent="handleConfirmLogin" tabindex="0">
        <a-spin :spinning="loading">
          <span class="button-text">confirm & login</span>
          <img src="@/assets/images/login_image/login_start.png" alt="" class="confirmation-button-wrap"
            v-if="!canLogin">
          <img src="@/assets/images/login_image/login_end.png" alt="" class="confirmation-button-wrap" v-else>
        </a-spin>
      </div>
      <a-modal v-model:open="open" title="" @ok="handleOk">
        <template #footer> </template>
        <div class="verification-section">
          <h1 class="verification-title">Email verification</h1>
          <p class="verification-message">
            An email has been sent to your inbox. Please check your email to verify.
          </p>
          <button key="back" @click="handleCancel" class="verification-action-button">
            GO TO MAIL
          </button>
        </div>
      </a-modal>
      <div class="sign_footer">
        <span class="account-prompt">Already have an account?</span>
        <a class="login_login-in" href="/mobile/login" @click.prevent="jumpToLoginPage" rel="noopener noreferrer">
          Login here</a>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed, ref } from "vue";
import { isPasswordStrong } from "@/utils/index";
import { firebaseService } from "@/services/firebaseService";
import { useRouter } from "vue-router";
import { openErrModal } from "@/utils/ui";
import RouterUtils from "@/utils/routerUtils";
import { useUserStore } from "@/store/user";
import { Spin } from 'ant-design-vue';

const router = useRouter();
const userStore = useUserStore();

const loading = ref<boolean>(false);
const open = ref<boolean>(false);
const password = ref("");
const confirmPassword = ref("");
const passwordError = ref("");
const routerUtils = new RouterUtils(router);
const canLogin = computed(() => !passwordError.value && password.value !== "" && confirmPassword.value !== "");

const handleOk = () => {
  loading.value = true;
  setTimeout(() => {
    loading.value = false;
    open.value = false;
  }, 2000);
};

const handleCancel = () => {
  open.value = false;
};

const jumpToLoginPage = () => {
  routerUtils.jumpToLoginPage();
};

const checkPassword = () => {
  if (!isPasswordStrong(password.value)) {
    passwordError.value = "Sorry, the passwords do not match. Please enter the same password.";
  } else if (password.value !== confirmPassword.value) {
    passwordError.value = "Passwords do not match.";
  } else {
    passwordError.value = "";
  }
};

const handleConfirmLogin = async () => {
  if (loading.value) return;

  loading.value = true;
  try {
    const userEmail = userStore.user.email;
    if (!userEmail) {
      openErrModal("email invalid");
      return;
    }
    const result = await firebaseService.setPassword(userEmail, confirmPassword.value);
    if (result.success) {
      routerUtils.jumpToMainPage();
    }
  } finally {
    loading.value = false;
  }
};
</script>
<style scoped>
.setPassword_wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
}

.form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form-title {
  margin-top: 2vh;
  font-size: 3vh;
  font-weight: bolder;
  z-index: 3;
}

/* 步骤条 */
.step-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: 2vh; */
  padding: 0 2vw;
  width: 35vw;
}

.step-one,
.step-two {
  width: 3vw;
  height: 3vw;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: white;
}

.step-one img,
.step-two img {
  width: 8vw;
  height: 8vw;
}

.step-number {
  position: absolute;
  font-size: 4.5vw;
}

.step-line {
  flex-grow: 1;
  height: 0;
  border: none;
  border-top: 5px dotted #8359ff;
  margin: 0 4vw;
}

.step-description {
  margin: 2.5vh 0 1vh 0;
  color: #6d52d9;
  font-size: 2.2vh;
  font-weight: bold;
}

.password-hint {
  color: #9297b3;
  /* margin: 1vw 0 0 0; */
  font-size: 3vw;
}

.error-message {
  color: red;
  font-size: 3vw;
  margin-top: 1vw;
  margin-left: 1.5vw;
  width: 60vw;
  z-index: 4;
}

/* 输入框 */
.account,
.password {
  margin: 1vw 0;
  width: 70vw;
  height: 6vh;
  border-radius: 8vw;
  font-size: 2vh;
  font-weight: bolder;
  padding: 0 3vw;
  background-color: #e5e7f4;
  display: flex;
  align-items: center;
}

.password {
  margin-top: 1.5vh;
}

.password input {
  border-radius: 5vw;
  height: 7vh;
  font-weight: bold;
  background-color: transparent !important;
}

/* 发送邮件按钮 */
.email-send-button {
  margin-top: 14vh;
}

.confirmation-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: -10vh;
  width: 70vw;
  height: 6vh;
  cursor: pointer;
}

.ant-spin-container {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.confirmation-button-wrap {
  position: absolute;
  object-fit: cover;
  width: 70vw;
  height: 6vh;
  border-radius: 8vw;
}

.button-text {
  color: rgb(230, 219, 253);
  font-size: 2.2vh;
  font-weight: bold;
  font-family: Helvetica, Helvetica;
  z-index: 100;
}

:where(.css-dev-only-do-not-override-1p3hq3p).ant-btn-primary {
  color: #fff !important;
  background-color: unset !important;
  box-shadow: none !important;
}

:where(.css-dev-only-do-not-override-1p3hq3p).ant-btn-primary:disabled {
  border-color: transparent !important;
}

/* 对话框 */
:where(.css-dev-only-do-not-override-1p3hq3p).ant-modal .ant-modal-content {
  border-radius: 40px;
  width: 400px;
  height: 350px;
}

.verification-section {
  font-family: Arial, sans-serif;
  text-align: center;
  padding: 50px 10px;
}

.verification-title {
  /* font-size: 2em; */
  font-size: 35px;
  font-weight: bold;
  background: linear-gradient(to right, #237fff, #ed5aff);
  -webkit-background-clip: text;
  color: transparent;
}

.verification-message {
  /* font-size: 1.2em; */
  font-size: 18px;
  color: #605086;
  margin: 20px 0;
}

.verification-action-button {
  position: absolute;
  /* font-size: 1em; */
  font-size: 18px;
  font-weight: bold;
  color: white;
  background: linear-gradient(to right, #ffb113, #9f3fff, #4393ff);
  border: none;
  border-radius: 30px;
  width: 300px;
  height: 45px;
  top: 250px;
  left: 25px;
  cursor: pointer;
}

/* 提示信息 */
.sign_footer {
  /* width: 55vw; */
  display: flex;
  margin: 1vh auto;
  flex-direction: row;
  justify-content: space-evenly;
}

.account-prompt {
  color: #180b38;
  font-size: 1.5vh;
}

.login_login-in {
  font-size: 1.5vh;
  color: #7555f2;
  text-decoration: underline;
  cursor: pointer;
}
</style>
