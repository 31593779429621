import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, withKeys as _withKeys, withModifiers as _withModifiers } from "vue"
import _imports_0 from '@/assets/images/login_image.png'
import _imports_1 from '@/assets/images/download_image.png'
import _imports_2 from '@/assets/images/download_icon.png'


const _hoisted_1 = { class: "app-top-nav" }
const _hoisted_2 = { class: "w" }
const _hoisted_3 = ["onKeydown"]
const _hoisted_4 = { class: "image-container" }
const _hoisted_5 = {
  key: 0,
  src: _imports_0,
  alt: "",
  class: "login"
}
const _hoisted_6 = {
  key: 1,
  class: "login_text"
}
const _hoisted_7 = { class: "popover-content" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { key: 2 }
const _hoisted_11 = { class: "interactive-link" }
const _hoisted_12 = {
  key: 0,
  class: "avatar-circle"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Router_Link = _resolveComponent("Router-Link")!
  const _component_a_popover = _resolveComponent("a-popover")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("ul", null, [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList($setup.navItems, (item, index) => {
          return _createElementVNode("li", { key: index }, [
            _createVNode(_component_Router_Link, {
              to: item.path,
              class: _normalizeClass({ active: $setup.currentRoute === item.path })
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(item.name), 1 /* TEXT */)
              ]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to", "class"])
          ])
        }), 64 /* STABLE_FRAGMENT */)),
        _cache[0] || (_cache[0] = _createElementVNode("li", null, [
          _createElementVNode("a", { href: "mailto:support@planetlinkinc.com?subject=Customer%20Service" }, "Contact")
        ], -1 /* HOISTED */))
      ]),
      _createElementVNode("a", {
        onClick: $setup.goToLoginPage,
        onKeydown: [
          _withKeys($setup.goToLoginPage, ["enter"]),
          _withKeys(_withModifiers($setup.goToLoginPage, ["prevent"]), ["space"])
        ],
        tabindex: "0",
        class: "interactive-link"
      }, [
        _createElementVNode("div", _hoisted_4, [
          (!$setup.isLoggedIn)
            ? (_openBlock(), _createElementBlock("img", _hoisted_5))
            : _createCommentVNode("v-if", true),
          (!$setup.isLoggedIn)
            ? (_openBlock(), _createElementBlock("span", _hoisted_6, "Login"))
            : _createCommentVNode("v-if", true)
        ])
      ], 40 /* PROPS, NEED_HYDRATION */, _hoisted_3),
      _createVNode(_component_a_popover, {
        onHide: $setup.onPopoverHide,
        placement: "bottom",
        getPopupContainer: 
          (triggerNode) => {
            return triggerNode.parentNode;
          }
        
      }, {
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("p", null, [
              _cache[1] || (_cache[1] = _createElementVNode("strong", null, "Account:", -1 /* HOISTED */)),
              _createTextVNode(" " + _toDisplayString($setup.userEmail), 1 /* TEXT */)
            ]),
            ($setup.subscriptionType)
              ? (_openBlock(), _createElementBlock("p", _hoisted_8, [
                  _cache[2] || (_cache[2] = _createElementVNode("strong", null, "Connection mode:", -1 /* HOISTED */)),
                  _cache[3] || (_cache[3] = _createTextVNode()),
                  _cache[4] || (_cache[4] = _createElementVNode("span", { class: "vip-badge" }, "VIP", -1 /* HOISTED */)),
                  _createTextVNode(" " + _toDisplayString($setup.subscriptionType) + " Plan ", 1 /* TEXT */)
                ]))
              : (_openBlock(), _createElementBlock("p", _hoisted_9, _cache[5] || (_cache[5] = [
                  _createElementVNode("strong", null, "Connection mode:", -1 /* HOISTED */),
                  _createTextVNode("Free")
                ]))),
            _cache[7] || (_cache[7] = _createElementVNode("p", null, [
              _createElementVNode("strong", null, "Devices:"),
              _createTextVNode(" 1/1 Devices")
            ], -1 /* HOISTED */)),
            ($setup.endDate)
              ? (_openBlock(), _createElementBlock("p", _hoisted_10, [
                  _cache[6] || (_cache[6] = _createElementVNode("strong", null, "Valid until:", -1 /* HOISTED */)),
                  _createTextVNode(_toDisplayString($setup.endDate), 1 /* TEXT */)
                ]))
              : _createCommentVNode("v-if", true),
            _createElementVNode("button", {
              onClick: $setup.signOut,
              class: "sign-out-button"
            }, "SIGN OUT")
          ])
        ]),
        default: _withCtx(() => [
          _createElementVNode("a", _hoisted_11, [
            ($setup.isLoggedIn)
              ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString($setup.userInitial), 1 /* TEXT */))
              : _createCommentVNode("v-if", true)
          ])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["getPopupContainer"]),
      _cache[8] || (_cache[8] = _createElementVNode("div", { class: "download" }, [
        _createElementVNode("img", {
          src: _imports_1,
          alt: "",
          class: "download_image"
        }),
        _createElementVNode("img", {
          src: _imports_2,
          alt: "",
          class: "download_icon"
        }),
        _createElementVNode("a", {
          href: "https://play.google.com/store/apps/details?id=com.freeguardvpn.app",
          class: "download-link"
        }, "DOWNLOADS")
      ], -1 /* HOISTED */))
    ])
  ]))
}