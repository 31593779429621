<template>
  <div class="wrap">
    <div class="center-wrap">
      <div class="info-wrap">
        <div class="title">
          <slot name="title">FREEGUARD VPN</slot>
        </div>
        <div class="content">
          <slot name="content">
            Unlimited fast & secure proxy Protect your online privacy with fast and secure free VPN proxy
          </slot>
        </div>
      </div>
      <div class="func-wrap">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
</script>
<style lang="less" scoped>
.wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  .center-wrap {
    display: flex;
    background-color: white;
    width: 58vw;
    height: 75vh;
    border-radius: 4vh;

    .info-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      flex: 1;
      background-image: url("@/assets/images/login_image/login-left-bg.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% 100%;
      padding: 3vw;

      .title {
        margin-top: -3vh;
        margin-left: -3vw;
        font-family: Helvetica, Helvetica;
        font-weight: bold;
        font-size: 3vh;
        color: #ffffff;
        line-height: 3.5vh;
        text-align: left;
        font-style: normal;
      }

      .content {
        margin-top: 3vh;
        margin-left: -1vw;
        font-family: Helvetica;
        font-size: 1.6vh;
        color: #FFFFFF;
        line-height: 2.8vh;
        text-align: left;
        font-style: normal;
      }
    }

    .func-wrap {
      flex: 2;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 3vw;
    }
  }
}
</style>
