<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
  <div class="register-wrap">
    <div class="input-wrap">
      <span class="sign_form-title">REGISTER</span><br />
      <div class="step-indicator">
        <div class="step-one">
          <img src="@/assets/images/sign/step-one.png" alt="" />
          <span class="step_one-num">1</span>
        </div>
        <hr class="line" />
        <div class="step-two">
          <img src="@/assets/images/sign/step-two.png" alt="" />
          <span class="step_two-num">2</span>
        </div>
      </div>
      <p class="step-description">Step 1: Enter your email</p>
      <a-input :value="email" @input="updateEmail" @blur="checkEmail" placeholder="Email" class="account" />
      <p v-if="errorMessage" class="error-message-email">{{ errorMessage }}</p>
      <p v-else class="tip-msg">Any character with 6 or more digits</p>
    </div>
    <div class="bottom-wrap">
      <div class="register-button-wrapper" @click="handleSendEmail">
        <span class="login_button-text">{{ sendText }}</span>
        <img src="@/assets/images/login_image/login_start.png" alt="" class="btn-wrap" v-if="!canSendEmail">
        <img src="@/assets/images/login_image/login_end.png" alt="" class="btn-wrap" v-else>
      </div>
      <div class="sign_footer">
        <span class="account-prompt">Already have an account?</span>
        <a class="login_login-in" @click="jumpToLoginPage" rel="noopener noreferrer">
          Login here</a>
      </div>
    </div>
    <a-modal v-model:open="open" title="" centered @ok="handleOk" class="verification">
      <template #footer> </template>
      <div class="verification-container">
        <h1 class="verification-title">Email verification</h1>
        <p class="verification-message">
          An email has been sent to your inbox. Please check your email to verify.
        </p>
        <button key="back" class="verification-button">
            <a href="mailto:support@planetlinkinc.com">GO TO MAIL</a>
        </button>
      </div>
    </a-modal>
  </div>
</template>
<script lang="ts" setup>
import { computed, ref } from "vue";
import { firebaseService } from "@/services/firebaseService";
import { canTriggerEmail, recordEmailTrigger, isValidEmail } from "@/utils/index";
import { useRouter } from "vue-router";
import RouterUtils from "@/utils/routerUtils";
import { useUserStore } from "@/store/user";

const router = useRouter();
const email = ref<string>("");
const loading = ref<boolean>(false);
const open = ref<boolean>(false);
const errorMessage = ref<string>("");
const resendTimer = ref<number>(0);
let timer: any = null;
const routerUtils = new RouterUtils(router);
const userStore = useUserStore();

const jumpToLoginPage = () => {
  routerUtils.jumpToLoginPage();
};

// 实时获取email
const updateEmail = (event: Event) => {
  const target = event.target as HTMLInputElement;
  email.value = target.value;
};

// 显示用户弹窗
const showModal = () => {
  open.value = true;
};

const isEmailValid = computed(() => isValidEmail(email.value));
const canSendEmail = computed(() => isEmailValid.value && resendTimer.value <= 0);

// 对email进行检查
const checkEmail = () => {
  if (!isEmailValid.value) {
    errorMessage.value = "Invalid email.";
  } else {
    errorMessage.value = "";
  }
};
// 给邮箱发送邮件
const sendEmail = async () => {
  await firebaseService.registerAndSendVerification(email.value);
  userStore.setUser({
    email: email.value,
  });
};

const sendText = computed(() => (resendTimer.value ? `RESEND AFTER ${resendTimer.value}S` : "SEND EMAIL"));

// 记录发送时间
const startResendTimer = () => {
  resendTimer.value = 60; // 设定60秒
  timer = setInterval(() => {
    if (resendTimer.value > 0) {
      resendTimer.value -= 1;
    } else {
      clearInterval(timer);
      timer = null;
    }
  }, 1000);
};

const handleSendEmail = async () => {
  if (canSendEmail.value) {
    const { canTrigger, remainingTime } = canTriggerEmail();
    if (canTrigger) {
      recordEmailTrigger();
      showModal();
      await sendEmail();
      errorMessage.value = "";
      startResendTimer();
    } else {
      errorMessage.value = `Failed to send verification email. Please try again after ${remainingTime} seconds.`;
      resendTimer.value = remainingTime;
    }
  }
};

const handleOk = () => {
  loading.value = true;
  setTimeout(() => {
    loading.value = false;
    open.value = false;
  }, 2000);
};
</script>
<style scoped lang="less">
.input-wrap {
  margin-top: 3vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bottom-wrap {
  // display: flex;
  // justify-content: center;
  // align-items: center;
  cursor: pointer;
}

.register-button-wrapper {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.login_button-text {
  position: absolute;
  color: rgb(230, 219, 253);
  font-size: 2.2vh;
  font-weight: bold;
}

//img
.btn-wrap {
  border-radius: 8vw;
  width: 70vw;
  height: 6vh;
  -o-object-fit: cover;
  object-fit: cover;
}

.register-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
}

.wrapper {
  text-align: left;
  width: fit-content;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.signContent_1 {
  position: absolute;
  top: 30%;
  left: 8%;
  width: 20vw;
}

.sign_title {
  color: #fff;
  font-size: 2vw;
  font-weight: bold;
}

.sign_subtitle {
  color: #fff;
  padding-top: 2vw;
  font-size: 1vw;
  font-weight: 200;
  line-height: 1.5;
}

.sign_form-title {
  font-size: 7vw;
  font-weight: bolder;
  font-family: Helvetica, Helvetica;
}

/* 步骤条 */
.step-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2vh;
  padding: 0 2vw;
  width: 35vw;
}

.step-one,
.step-two {
  position: relative;
  width: 3vw;
  height: 3vw;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: white;
}

.step-one img,
.step-two img {
  width: 8vw;
  height: 8vw;
}

.step_one-num,
.step_two-num {
  position: absolute;
  font-size: 4.5vw;
}

.line {
  flex-grow: 1;
  height: 0;
  border: none;
  border-top: 5px dotted #b0b0b0;
  margin: 0 4vw;
}

.step-description {
  margin: 2.5vh 0 5vh 0;
  color: #6d52d9;
  font-size: 2.5vh;
  font-weight: bold;
}

/* 输入框 */
.account {
  padding: 0 4vw;
  margin-top: -1vh;
  margin-bottom: 1vh;
  width: 70vw;
  height: 6vh;
  border-radius: 8vw;
  font-size: 2vh;
  font-weight: bolder;
}

/* 发送邮件按钮 */
.sign_start {
  position: absolute;
  bottom: 20%;
  left: 70%;
  transform: translateX(-50%);
  width: 25vw;
  height: 8vh;
}

.send-email {
  position: absolute;
  top: -25%;
  left: 8%;
  color: rgb(230, 219, 253);
  font-size: 2vw;
  font-weight: bold;
}

.resend-email {
  position: absolute;
  top: -8% !important;
  left: 10%;
  color: rgb(230, 219, 253);
  font-size: 2vw;
  font-weight: bold;
}

:where(.css-dev-only-do-not-override-1p3hq3p).ant-btn-primary {
  color: #fff !important;
  background-color: unset !important;
  box-shadow: none !important;
}

:where(.css-dev-only-do-not-override-1p3hq3p).ant-btn-primary:disabled {
  border-color: transparent !important;
}

.error-message-email {
  display: flex;
  justify-content: center;
  color: red;
  font-size: 3vw;
  width: 60vw;
  margin-left: 3.5vw;
}

.tip-msg {
  display: flex;
  justify-content: center;
  color: #9297b3;
  font-size: 3vw;
  margin-top: 0.8vh;
}

.resend-button {
  border-radius: 30px;
  color: white;
  font-weight: bold;
  padding: 10px 20px;
  border: none;
  transition: background 0.3s ease;
}

.resend-button:disabled {
  width: 445px;
  height: 70px;
}

/* 发送邮件按钮监听事件 */
.sign_button {
  color: rgb(230, 219, 253);
  font-size: 30px;
  font-weight: bold;
  transition: background-color 0.3s;
}

.verification .ant-modal-content {
  width: 70vw !important;
  height: 38vh !important;
  border-radius: 5vw !important;
}

.verification-title {
  margin-bottom: 3vh;
  font-size: 6vw;
  font-weight: bold;
  background: linear-gradient(to right, #237fff, #ed5aff);
  -webkit-background-clip: text;
  color: transparent;
}

.verification-message {
  font-size: 4.5vw;
  color: #605086;
  text-align: center;
}

.verification-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5vh 0 5vw;
}

.verification-button {
  margin-top: 5vh;
  font-size: 5vw;
  font-weight: bold;
  color: white;
  background: linear-gradient(to right, #ffb113, #9f3fff, #4393ff);
  border: none;
  border-radius: 8vw;
  width: 80vw;
  height: 6.5vh;
  cursor: pointer;
}

.verification-button a {
  color: white !important;
}

/* 提示信息 */
.sign_footer {
  display: flex;
  justify-content: space-evenly;
}

.account-prompt {
  color: #180b38;
  font-size: 1.5vh;
}

.login_login-in {
  color: #7555f2;
  text-decoration: underline;
  font-size: 1.5vh;
  cursor: pointer;
}

:where(.css-dev-only-do-not-override-1p3hq3p).ant-modal .ant-modal-content {
  width: 70vw !important;
  height: 38vh !important;
  border-radius: 5vw !important;
}

// .css-dev-only-do-not-override-1p3hq3p .ant-modal .ant-modal-content {
//   width: 70vw !important;
//   height: 38vh !important;
//   border-radius: 5vw !important;</style>
