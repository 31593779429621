import { userLoginV2Req, userLoginV2Resp } from "@/typings/api/loginServices";
// eslint-disable-next-line import/no-cycle
import BaseService from "./baseServices";

export const LoginConfig = {
  userLogin: {
    cgi: "user/login",
  },
};

class LoginService extends BaseService {
  public async userLogin(payload: userLoginV2Req) {
    const respData = await this.sendFormData<userLoginV2Resp>({
      cgi: LoginConfig.userLogin.cgi,
      payload,
      disableAuth: true, // 登录的时候不需要 token
    });
    return respData;
  }
}

export default new LoginService();
