import loginService from '@/services/api/loginService';
import { getIdToken } from '@/services/apiService';
import { firebaseService } from '@/services/firebaseService';
import { isApiWorkWell } from '@/utils/network';
import { openErrModal } from '@/utils/ui';
import { defineStore } from 'pinia';
import { STATUS_CODE } from '@/consts/network';
import urlUtils from '@/utils/urlUtils';
import { useUserStore } from './user';

export const useLoginStore = defineStore("login", () => {
  const userStore = useUserStore();

  async function signOut() {
    const result = await firebaseService.signOut();
    if (result.success) {
      userStore.clear();
      return true;
    }
    return false;
  }

  async function login(email: string, password: string) {
    const result = await firebaseService.login(email, password);
    if (result.success) {
      // 登录成功后，通知业务后台
      // 1. 获取 firebase idToken
      const idToken = await getIdToken();

      // 2. 跟业务后台换取 token
      if (idToken) {
        // 2.1 从后台获取 token
        const loginResult = await loginService.userLogin({
          idtoken: idToken,
          password,
        });
        // 2.2 更新数据
        if (isApiWorkWell(loginResult)) {
          userStore.setUser({
            username: email,
            email,
            idToken,
            token: loginResult.data.token,
            isVip: !!loginResult.data.isvip,
          }); // 更新 store 中的 user 数据
          return true;
        }
        openErrModal("login api error");
        console.error("login error: ", loginResult);
      }
    }
    return false;
  }

  return {
    login,
    signOut,
  };
});
