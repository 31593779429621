<template>
  <img src="@/assets/images/mobile-subscribe-bg.png" alt="" class="container_bg">
  <div class="container">
    <div class="upgrade-header">
      <router-link to="/">
        <img src="@/assets/images/return_key.png" alt="" class="return_key" />
      </router-link>
      <h1 class="title">Upgrade To Premium</h1>
    </div>
    <div class="features">
      <div class="feature-item" v-for="(feature, index) in features" :key="index">
        <h2>{{ feature.title }}</h2>
        <p>{{ feature.description }}</p>
      </div>
    </div>
    <div class="plans">
      <div class="plan-year plan" @click="selectPlan" @keydown.enter.space="selectPlan" tabindex="0" data-type="yearly"
        :data-price-id="productList[0]?.price_id">
        <img src="@/assets/images/selected_bg-large.png" alt="" class="large" v-if="selectedPlan === 'yearly'">
        <img src="@/assets/images/vip_bg-big.png" alt="" class="large" v-else>
        <div class="save">
          <img src="@/assets/images/save.png" alt="" class="save">
          <span class="save-price">Save </span>
          <span class="discount-text">{{ discountPercentage }}</span>
        </div>
        <div class="subscribePlanYear" :class="{ 'dark-text': selectedPlan === 'yearly' }">
          <div class="plan-details">
            <h3 class="plan-title">Yearly Plan</h3>
            <small class="plan-trial">3-day trial for $0.1</small>
          </div>
          <div class="plan-pricing">
            <p class="plan-price">
              $<span>{{ amounts[0] }}</span>/year
            </p>
            <small class="plan-discount">
              <span>$</span>
              <span>{{ Number(amounts[2]) * 48 }}</span>
              <span>/year</span>
            </small>
          </div>
        </div>
      </div>
      <div class="plan-small">
        <div class="month small plan" @click="selectPlan" @keydown.enter.space="selectPlan" tabindex="0"
          data-type="monthly" :data-price-id="productList[1]?.price_id"
          :class="{ 'dark-text': selectedPlan === 'monthly' }">
          <img src="@/assets/images/selected_bg-small.png" alt="" class="large" v-if="selectedPlan === 'monthly'">
          <img src="@/assets/images/vip_bg-small.png" alt="" class="small" v-else>
          <h3>Monthly Plan</h3>
          <p>
            $<span>{{ amounts[1] }}</span>/month
          </p>
        </div>
        <div class="week small plan" @click="selectPlan" @keydown.enter.space="selectPlan" tabindex="0"
          data-type="weekly" :data-price-id="productList[2]?.price_id"
          :class="{ 'dark-text': selectedPlan === 'weekly' }">
          <img src="@/assets/images/selected_bg-small.png" alt="" class="large" v-if="selectedPlan === 'weekly'">
          <img src="@/assets/images/vip_bg-small.png" alt="" class="small" v-else>
          <h3>Weekly Plan</h3>
          <p>
            $<span>{{ amounts[2] }}</span>/week
          </p>
        </div>
      </div>
    </div>
    <button class="subscribe-button" @click="handleSubscribe">
      <img src="@/assets/images/vip-buttom.png" alt="">
      <span>SUBSCRIBE</span>
    </button>
  </div>
</template>

<script lang="ts" setup>
import { CANCEL_URL, SUCCESS_URL } from "@/consts/mode";
import paymentServices from "@/services/api/paymentServices";
import stripeService from "@/services/stripeService";
import { BaseResp } from "@/typings/api/baseServices";
import { GetProductsResp, productItem } from "@/typings/api/paymentServices";
import { isApiWorkWell } from "@/utils/network";
import { reactive, ref, onMounted } from "vue";

const amounts = ref<string[]>([]);
const productList = ref<productItem[]>([]);
const selectedPriceId = ref<string | null>(null); // 用于存储选中的 price_id
const selectedPlan = ref<string | null>(null);
const discountPercentage = ref('');

const features = reactive([
  {
    title: "Speed Boost",
    description:
      "Experience over 4x faster speeds, depending on local network conditions.",
  },
  {
    title: "Expanded Nodes",
    description: "Access a wider range of geographic locations.",
  },
  {
    title: "Enhanced Privacy",
    description:
      "Leverage virtual network card technology and proprietary protocols for superior traffic camouflage and privacy.",
  },
  {
    title: "Broader App Support",
    description:
      "Enjoy a richer selection of apps and websites, including ChatGPT, Netflix, and more.",
  },
]);

function selectPlan(event: Event) {
  const element = event.currentTarget as HTMLElement;
  const type = element.getAttribute("data-type");
  const priceId = element.getAttribute("data-price-id");

  selectedPlan.value = type;
  // 保存选中的套餐类型和 price_id
  selectedPriceId.value = priceId; // 保存选中的 price_id
}

const fetchProducts = async () => {
  try {
    const response: BaseResp<GetProductsResp> = await paymentServices.getProducts();
    productList.value = response.data.price_info;

    if (Array.isArray(productList.value)) {
      amounts.value = productList.value.map((product) => (product.amount / 100).toFixed(2));
      const discountValue = 1 - (Number(amounts.value[0]) / (Number(amounts.value[2]) * 48));
      discountPercentage.value = `${(discountValue * 100).toFixed(0)}%`;
    } else {
      console.error("Expected products to be an array, but got:", productList);
    }
  } catch (error) {
    console.error("Error fetching products:", error);
  }
};

async function startPay(priceId: string) {
  const resp = await paymentServices.stripeCheckout({
    priceId,
    cancelURL: CANCEL_URL,
    successURL: SUCCESS_URL,
  });

  if (isApiWorkWell(resp)) {
    const sessionId = resp.data.checkoutSession.id;
    await stripeService.redirectToCheckout({
      sessionId,
    });
  }
}

// 处理订阅按钮点击
function handleSubscribe() {
  if (selectedPriceId.value) {
    startPay(selectedPriceId.value);
  } else {
    console.error("No plan selected");
  }
}

onMounted(() => {
  fetchProducts();
});
</script>

<style scoped>
body {
  margin: 0;
  font-family: Arial, sans-serif;
  color: white;
  text-align: center;
}

.container_bg {
  position: relative;
  width: 100%;
  height: 110vh;
  object-fit: cover;
}

.container {
  position: absolute;
  padding: 20px;
  width: 100%;
}

.upgrade-header {
  display: flex;
  align-items: center;
  margin-top: 1vh;
}

.return_key {
  display: inline-block;
  width: 7vw;
  height: 7vw;
}

.title {
  text-align: center;
  margin: 0 auto;
  color: #fff;
  font-size: 5vw;
}

.features {
  margin-top: 4vh;
  background: linear-gradient(137deg,
      rgba(67, 78, 143, 0.6) 0%,
      rgba(39, 21, 79, 0.5) 100%);
  border-radius: 6vw;
  padding: 4vw 4vw 4vw 5vw;
  margin-bottom: 5vh;
}

.feature-item {
  margin-bottom: 15px;
  z-index: 1;
}

.feature-item h2 {
  margin-top: 2vh;
  margin-bottom: 0.5vh;
  font-size: 4.5vw;
  color: #fff;
}

.feature-item p {
  color: #bf89e1d8;
}

.plans {
  margin-top: 8vh;
  max-width: 768px;
}

.plan-year {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dark-text {
  color: #7732E2 !important;
}

.large {
  width: 100%;
  height: 100%;
}

.subscribePlanYear {
  padding: 20px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #ddabd5;
}

.save {
  position: absolute;
  top: -1.5vw;
  left: -0.4vh;
  width: 50%;
}

.save-price {
  position: absolute;
  top: -0.1vh;
  left: 2vw;
  color: #fff;
  font-size: 3.8vw;
  font-weight: bold;
}

.discount-text {
  position: absolute;
  top: -0.2vh;
  left: 12vw;
  color: #fff;
  font-size: 3.8vw;
  font-weight: bold;
}

.plan-details {
  font-family: Helvetica, Helvetica;
  margin-left: 1.5vw;
}

.plan-title {
  margin-bottom: 0;
  font-family: Helvetica, Helvetica;
  font-style: italic;
  font-weight: bold;
  font-size: 5vw;
}

.plan-trial {
  margin-left: 0.5vw;
  font-size: 3.5vw;
}

.plan-pricing {
  margin-left: 20vw;
}

.plan-price {
  margin-bottom: 0;
  font-size: 3.5vw;
}

.plan-price span {
  font-size: 6vw;
  font-weight: bold;
}

.plan-discount {
  font-size: 3.5vw;
  text-decoration: line-through;
}

.plan-small {
  display: flex;
  justify-content: space-between;
  margin-top: 1vh;
}

.small {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 1vh;
  width: 100%;
}

.week,
.month {
  font-size: 3vw;
  font-family: Helvetica,
    Helvetica;
  color: #ddabd5;
  align-items: flex-start;
}

.week {
  margin-left: 10vw;
}

.small h3 {
  position: absolute;
  top: 3vh;
  font-weight: bold;
  font-style: italic;
  margin-left: 5vw;
  /* margin-left: 5vw;
  margin-top: 3.5vh;
  margin-bottom: 1vh; */
}

.small span {
  font-size: 6vw;
  font-weight: bold;
}

.small p {
  position: absolute;
  top: 6vh;
  vertical-align: bottom;
  margin-left: 5vw;
  /* margin-left: 5vw;
  margin-top: 9vh;  */
}

.subscribe-button {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 5vh;
  border: none;
  cursor: pointer;
  background-color: transparent;
}

.subscribe-button img {
  position: relative;
  display: inline-block;
  width: 100%;
  object-fit: cover;
}

.subscribe-button span {
  position: absolute;
  color: #fff;
  font-size: 5vw;
  text-shadow: 0.5vw 0.5vw 0 #ff7602, -0.5vw 0.5vw 0 #ff7602, 0.5vw -0.5vw 0 #ff7602,
    -0.5vw -0.5vw 0 #ff7602;
}
</style>
