import { defineComponent as _defineComponent } from 'vue'
import { computed, ref } from "vue";
import { isPasswordStrong } from "@/utils/index";
import { firebaseService } from "@/services/firebaseService";
import { useRouter } from "vue-router";
import { openErrModal } from "@/utils/ui";
import RouterUtils from "@/utils/routerUtils";
import { useUserStore } from "@/store/user";
import { Spin } from 'ant-design-vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'SetPassWord-mobile',
  setup(__props, { expose: __expose }) {
  __expose();

const router = useRouter();
const userStore = useUserStore();

const loading = ref<boolean>(false);
const open = ref<boolean>(false);
const password = ref("");
const confirmPassword = ref("");
const passwordError = ref("");
const routerUtils = new RouterUtils(router);
const canLogin = computed(() => !passwordError.value && password.value !== "" && confirmPassword.value !== "");

const handleOk = () => {
  loading.value = true;
  setTimeout(() => {
    loading.value = false;
    open.value = false;
  }, 2000);
};

const handleCancel = () => {
  open.value = false;
};

const jumpToLoginPage = () => {
  routerUtils.jumpToLoginPage();
};

const checkPassword = () => {
  if (!isPasswordStrong(password.value)) {
    passwordError.value = "Sorry, the passwords do not match. Please enter the same password.";
  } else if (password.value !== confirmPassword.value) {
    passwordError.value = "Passwords do not match.";
  } else {
    passwordError.value = "";
  }
};

const handleConfirmLogin = async () => {
  if (loading.value) return;

  loading.value = true;
  try {
    const userEmail = userStore.user.email;
    if (!userEmail) {
      openErrModal("email invalid");
      return;
    }
    const result = await firebaseService.setPassword(userEmail, confirmPassword.value);
    if (result.success) {
      routerUtils.jumpToMainPage();
    }
  } finally {
    loading.value = false;
  }
};

const __returned__ = { router, userStore, loading, open, password, confirmPassword, passwordError, routerUtils, canLogin, handleOk, handleCancel, jumpToLoginPage, checkPassword, handleConfirmLogin }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})