<template>
  <div class="app-top-nav">
    <div class="w">
      <ul>
        <li v-for="(item, index) in navItems" :key="index">
          <Router-Link :to="item.path" :class="{ active: currentRoute === item.path }">
            {{ item.name }}
          </Router-Link>
        </li>
        <li>
          <a href="mailto:support@planetlinkinc.com?subject=Customer%20Service"
            >Contact</a
          >
        </li>
      </ul>
      <a
        @click="goToLoginPage"
        @keydown.enter="goToLoginPage"
        @keydown.space.prevent="goToLoginPage"
        tabindex="0"
        class="interactive-link"
      >
        <div class="image-container">
          <img
            v-if="!isLoggedIn"
            src="@/assets/images/login_image.png"
            alt=""
            class="login"
          />
          <span v-if="!isLoggedIn" class="login_text">Login</span>
        </div>
      </a>
      <a-popover
        @hide="onPopoverHide"
        placement="bottom"
        :getPopupContainer="
          (triggerNode: HTMLElement) => {
            return triggerNode.parentNode;
          }
        "
      >
        <template #content>
          <div class="popover-content">
            <p><strong>Account:</strong> {{ userEmail }}</p>
            <p v-if="subscriptionType">
              <strong>Connection mode:</strong> <span class="vip-badge">VIP</span>
              {{ subscriptionType }}
              Plan
            </p>
            <p v-else><strong>Connection mode:</strong>Free</p>
            <p><strong>Devices:</strong> 1/1 Devices</p>
            <p v-if="endDate"><strong>Valid until:</strong>{{ endDate }}</p>
            <button @click="signOut" class="sign-out-button">SIGN OUT</button>
          </div>
        </template>
        <a class="interactive-link">
          <span v-if="isLoggedIn" class="avatar-circle">{{ userInitial }}</span>
        </a>
      </a-popover>

      <div class="download">
        <img src="@/assets/images/download_image.png" alt="" class="download_image" />
        <img src="@/assets/images/download_icon.png" alt="" class="download_icon" />
        <a
          href="https://play.google.com/store/apps/details?id=com.freeguardvpn.app"
          class="download-link"
          >DOWNLOADS</a
        >
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { firebaseService } from "@/services/firebaseService";
import { useUserStore } from "@/store/user";
import RouterUtils from "@/utils/routerUtils";
import { useLoginStore } from "@/store/login";

// 获取当前路由
const router = useRouter();
const route = useRoute();

// 定义响应式变量
const userStore = useUserStore();
const loginStore = useLoginStore();

const userEmail = computed(() => userStore.user.email);

const isLoggedIn = computed(() => !!userEmail.value);
const currentRoute = computed(() => route.path);

const userInitial = computed(() => (userEmail.value ? userEmail.value.charAt(0).toUpperCase() : ""));

const subscriptionType = computed(() => userStore.user.subscriptionType || "");

const endDate = computed(() => userStore.user.endDate);

const routerUtils = new RouterUtils(router);

function goToLoginPage() {
  routerUtils.jumpToLoginPage();
}

function onPopoverHide() {
  const popoverElements = document.querySelectorAll(".ant-popover");
  popoverElements.forEach((element) => {
    const el = element as HTMLElement;
    el.style.display = "none";
  });
}

// 导航项
const navItems = [
  { name: "Home", path: "/" },
  { name: "Terms of service", path: "/terms" },
  { name: "Privacy policy", path: "/privacy" },
  { name: "IOS Privacy Policy", path: "/iosprivacy" },
];

const signOut = async () => {
  const signOutSuccess = await loginStore.signOut();
  if (signOutSuccess) {
    setTimeout(() => {
      onPopoverHide();
    }, 0);
  }
};
</script>

<style lang="less" scoped>
.app-top-nav {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  height: 80px;
  background-color: rgb(24, 21, 48);
  z-index: 3;
  line-height: 80px;

  ul {
    display: flex;

    li {
      position: relative;
      left: -8%;
      width: auto;

      a:first-child {
        padding: 0 30px;
      }

      a {
        font-family: PingFang SC;
        padding: 0 30px;
        color: #d7d6e3;
        font-weight: 500;
        font-size: 16px;
        transition: color 0.3s;

        &:hover,
        &.active {
          background: repeating-linear-gradient(to right, #fad62c, #e664ff, #4faeff);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }
}

.w {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1200px;
  margin: 0 auto;
}

.download {
  position: relative;
  left: 2%;
  align-items: center;
  display: flex;
}

.image-container {
  margin-left: 5vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: fit-content;
}

.login {
  display: block;
  // height: 5vh;
}

.login_text {
  position: absolute;
  font-size: 1.2vw;
  /* 使用相对单位 */
  background: repeating-linear-gradient(to right, #237fff, #75d5ff, #ed5aff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  pointer-events: none;
}

a-popover {
  margin-left: 2vw;
}

.interactive-link {
  margin-bottom: 1.5vh;
  cursor: pointer;
  z-index: 3;
}

.account_info {
  background-color: rgb(56, 48, 98);
}

// 弹窗
.popover-content {
  color: rgb(168, 174, 244);
  padding: 20px 40px;
}

.w ::v-deep(.ant-popover-inner) {
  background-color: rgb(56, 48, 98) !important;
  border-radius: 25px !important;
}

.ant-popover-content,
.ant-popover-inner-content,
.popover-content {
  background-color: rgb(56, 48, 98) !important;
  border-radius: 25px !important;
}

.ant-popover-inner {
  width: 350px !important;
  height: 250px !important;
}

// 弹窗小三角
.ant-popover-arrow::before,
.ant-popover-arrow::after {
  background-color: rgb(56, 48, 98) !important;
}

.popover-content p {
  margin: 10px 0;
  font-size: 14px;
}

.vip-badge {
  background-color: #fbc02d;
  color: #fff;
  padding: 2px 4px;
  border-radius: 3px;
  font-weight: bold;
}

.sign-out-button {
  margin-top: 20px;
  margin-left: 35px;
  padding: 10px 50px;
  background-color: #8e99f3;
  color: #fff;
  border: none;
  border-radius: 50px;
  cursor: pointer;
}

.sign-out-button:hover {
  background-color: #5c6bc0;
}

.avatar-circle {
  // margin-left: 2vw;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: #4caf50;
  color: white;
  font-size: 20px;
  border-radius: 50%;
  border: 2px solid white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  position: relative;
  overflow: hidden;
}

.download {
  margin-bottom: 1.5vh;
  position: relative;
  align-items: center;
  display: inline-block;
}

.download_image {
  width: 175px;
  height: 40px;
}

.download_icon {
  position: absolute;
  left: 14%;
  top: 50%;
  transform: translateY(-50%);
  width: 12%;
  height: 26%;
  z-index: 2;
}

.download-link {
  position: absolute;
  text-align: center;
  line-height: 2.375rem;
  /* 使用相对单位 */
  top: 52%;
  left: 58%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 1rem;
  /* 使用相对单位 */
  font-family: PingFang SC;
  font-weight: 450;
}
</style>
