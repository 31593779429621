import { defineComponent as _defineComponent } from 'vue'
import { computed, ref } from "vue";
import { firebaseService } from "@/services/firebaseService";
import { canTriggerEmail, recordEmailTrigger, isValidEmail } from "@/utils/index";
import { useRouter } from "vue-router";
import RouterUtils from "@/utils/routerUtils";
import { useUserStore } from "@/store/user";


export default /*@__PURE__*/_defineComponent({
  __name: 'RegisterComponent-mobile',
  setup(__props, { expose: __expose }) {
  __expose();

const router = useRouter();
const email = ref<string>("");
const loading = ref<boolean>(false);
const open = ref<boolean>(false);
const errorMessage = ref<string>("");
const resendTimer = ref<number>(0);
let timer: any = null;
const routerUtils = new RouterUtils(router);
const userStore = useUserStore();

const jumpToLoginPage = () => {
  routerUtils.jumpToLoginPage();
};

// 实时获取email
const updateEmail = (event: Event) => {
  const target = event.target as HTMLInputElement;
  email.value = target.value;
};

// 显示用户弹窗
const showModal = () => {
  open.value = true;
};

const isEmailValid = computed(() => isValidEmail(email.value));
const canSendEmail = computed(() => isEmailValid.value && resendTimer.value <= 0);

// 对email进行检查
const checkEmail = () => {
  if (!isEmailValid.value) {
    errorMessage.value = "Invalid email.";
  } else {
    errorMessage.value = "";
  }
};
// 给邮箱发送邮件
const sendEmail = async () => {
  await firebaseService.registerAndSendVerification(email.value);
  userStore.setUser({
    email: email.value,
  });
};

const sendText = computed(() => (resendTimer.value ? `RESEND AFTER ${resendTimer.value}S` : "SEND EMAIL"));

// 记录发送时间
const startResendTimer = () => {
  resendTimer.value = 60; // 设定60秒
  timer = setInterval(() => {
    if (resendTimer.value > 0) {
      resendTimer.value -= 1;
    } else {
      clearInterval(timer);
      timer = null;
    }
  }, 1000);
};

const handleSendEmail = async () => {
  if (canSendEmail.value) {
    const { canTrigger, remainingTime } = canTriggerEmail();
    if (canTrigger) {
      recordEmailTrigger();
      showModal();
      await sendEmail();
      errorMessage.value = "";
      startResendTimer();
    } else {
      errorMessage.value = `Failed to send verification email. Please try again after ${remainingTime} seconds.`;
      resendTimer.value = remainingTime;
    }
  }
};

const handleOk = () => {
  loading.value = true;
  setTimeout(() => {
    loading.value = false;
    open.value = false;
  }, 2000);
};

const __returned__ = { router, email, loading, open, errorMessage, resendTimer, get timer() { return timer }, set timer(v) { timer = v }, routerUtils, userStore, jumpToLoginPage, updateEmail, showModal, isEmailValid, canSendEmail, checkEmail, sendEmail, sendText, startResendTimer, handleSendEmail, handleOk }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})