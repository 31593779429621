import { defineComponent as _defineComponent } from 'vue'
import { CANCEL_URL, SUCCESS_URL } from "@/consts/mode";
import paymentServices from "@/services/api/paymentServices";
import stripeService from "@/services/stripeService";
import { BaseResp } from "@/typings/api/baseServices";
import { GetProductsResp, productItem } from "@/typings/api/paymentServices";
import { isApiWorkWell } from "@/utils/network";
import { reactive, ref, onMounted } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'SubscriptionOptions',
  setup(__props, { expose: __expose }) {
  __expose();

const amounts = ref<string[]>([]);
const productList = ref<productItem[]>([]);
const selectedPriceId = ref<string | null>(null); // 用于存储选中的 price_id
const selectedPlan = ref<string | null>(null);
const discountPercentage = ref('');

const features = reactive([
  {
    title: "Speed Boost",
    description:
      "Experience over 4x faster speeds, depending on local network conditions.",
  },
  {
    title: "Expanded Nodes",
    description: "Access a wider range of geographic locations.",
  },
  {
    title: "Enhanced Privacy",
    description:
      "Leverage virtual network card technology and proprietary protocols for superior traffic camouflage and privacy.",
  },
  {
    title: "Broader App Support",
    description:
      "Enjoy a richer selection of apps and websites, including ChatGPT, Netflix, and more.",
  },
]);

function selectPlan(event: Event) {
  const element = event.currentTarget as HTMLElement;
  const type = element.getAttribute("data-type");
  const priceId = element.getAttribute("data-price-id");

  selectedPlan.value = type;
  // 保存选中的套餐类型和 price_id
  selectedPriceId.value = priceId; // 保存选中的 price_id
}

const fetchProducts = async () => {
  try {
    const response: BaseResp<GetProductsResp> = await paymentServices.getProducts();
    productList.value = response.data.price_info;

    if (Array.isArray(productList.value)) {
      amounts.value = productList.value.map((product) => (product.amount / 100).toFixed(2));
      const discountValue = 1 - (Number(amounts.value[0]) / (Number(amounts.value[2]) * 48));
      discountPercentage.value = `${(discountValue * 100).toFixed(0)}%`;
    } else {
      console.error("Expected products to be an array, but got:", productList);
    }
  } catch (error) {
    console.error("Error fetching products:", error);
  }
};

async function startPay(priceId: string) {
  const resp = await paymentServices.stripeCheckout({
    priceId,
    cancelURL: CANCEL_URL,
    successURL: SUCCESS_URL,
  });

  if (isApiWorkWell(resp)) {
    const sessionId = resp.data.checkoutSession.id;
    await stripeService.redirectToCheckout({
      sessionId,
    });
  }
}

// 处理订阅按钮点击
function handleSubscribe() {
  if (selectedPriceId.value) {
    startPay(selectedPriceId.value);
  } else {
    console.error("No plan selected");
  }
}

onMounted(() => {
  fetchProducts();
});

const __returned__ = { amounts, productList, selectedPriceId, selectedPlan, discountPercentage, features, selectPlan, fetchProducts, startPay, handleSubscribe }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})