import BaseService from "@/services/api/baseServices";
import { BaseResp } from "@/typings/api/baseServices";
import {
  GetProductsReq,
  GetProductsResp,
  GetSubscriptionReq,
  GetSubscriptionResp,
  StripeCheckoutReq,
  StripeCheckoutResp,
  SubmitSubscriptionReq,
  SubmitSubscriptionResp,
} from "@/typings/api/paymentServices";
import { useUserStore } from "@/store/user";

export const PaymentVersion = "v2";

export const PaymentConfig = {
  getProducts: {
    cgi: "user/get/products",
  },
  getSubscription: {
    cgi: "user/get/subscription",
  },
  submitSubscription: {
    cgi: "user/subscription",
  },
  stripeCheckout: {
    cgi: "user/create/stripe/checkout",
  },
};

class PayMentServices extends BaseService {
  public async getProducts(): Promise<BaseResp<GetProductsResp>> {
    const userStore = useUserStore();
    const payload: GetProductsReq = {
      email: userStore.user.email,
    };
    const respData = await this.sendFormData<GetProductsResp>(
      {
        cgi: PaymentConfig.getProducts.cgi,
        payload,
      },
    );
    return respData;
  }

  public async getSubscription(): Promise<BaseResp<GetSubscriptionResp>> {
    const userStore = useUserStore();
    const payload: GetSubscriptionReq = {
      email: userStore.user.email,
    };
    const respData = await this.sendFormData<GetSubscriptionResp>(
      {
        cgi: PaymentConfig.getSubscription.cgi,
        payload,
      },
    );
    return respData;
  }

  public async submitSubscription(payload: SubmitSubscriptionReq): Promise<BaseResp<SubmitSubscriptionResp>> {
    const userStore = useUserStore();
    Object.assign(payload, {
      email: userStore.user.email,
    });
    const respData = await this.sendFormData<SubmitSubscriptionResp>(
      {
        cgi: PaymentConfig.submitSubscription.cgi,
        payload,
      },
    );
    return respData;
  }

  public async stripeCheckout(payload: StripeCheckoutReq): Promise<BaseResp<StripeCheckoutResp>> {
    const respData = await this.sendFormData<StripeCheckoutResp>(
      {
        cgi: PaymentConfig.stripeCheckout.cgi,
        payload,
      },
    );
    return respData;
  }
}

const paymentServices = new PayMentServices();
export default paymentServices;
