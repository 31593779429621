import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withKeys as _withKeys } from "vue"
import _imports_0 from '@/assets/images/mobile-subscribe-bg.png'
import _imports_1 from '@/assets/images/return_key.png'
import _imports_2 from '@/assets/images/selected_bg-large.png'
import _imports_3 from '@/assets/images/vip_bg-big.png'
import _imports_4 from '@/assets/images/save.png'
import _imports_5 from '@/assets/images/selected_bg-small.png'
import _imports_6 from '@/assets/images/vip_bg-small.png'
import _imports_7 from '@/assets/images/vip-buttom.png'


const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "upgrade-header" }
const _hoisted_3 = { class: "features" }
const _hoisted_4 = { class: "plans" }
const _hoisted_5 = ["data-price-id"]
const _hoisted_6 = {
  key: 0,
  src: _imports_2,
  alt: "",
  class: "large"
}
const _hoisted_7 = {
  key: 1,
  src: _imports_3,
  alt: "",
  class: "large"
}
const _hoisted_8 = { class: "save" }
const _hoisted_9 = { class: "discount-text" }
const _hoisted_10 = { class: "plan-pricing" }
const _hoisted_11 = { class: "plan-price" }
const _hoisted_12 = { class: "plan-discount" }
const _hoisted_13 = { class: "plan-small" }
const _hoisted_14 = ["data-price-id"]
const _hoisted_15 = {
  key: 0,
  src: _imports_5,
  alt: "",
  class: "large"
}
const _hoisted_16 = {
  key: 1,
  src: _imports_6,
  alt: "",
  class: "small"
}
const _hoisted_17 = ["data-price-id"]
const _hoisted_18 = {
  key: 0,
  src: _imports_5,
  alt: "",
  class: "large"
}
const _hoisted_19 = {
  key: 1,
  src: _imports_6,
  alt: "",
  class: "small"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[16] || (_cache[16] = _createElementVNode("img", {
      src: _imports_0,
      alt: "",
      class: "container_bg"
    }, null, -1 /* HOISTED */)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_router_link, { to: "/" }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createElementVNode("img", {
              src: _imports_1,
              alt: "",
              class: "return_key"
            }, null, -1 /* HOISTED */)
          ])),
          _: 1 /* STABLE */
        }),
        _cache[1] || (_cache[1] = _createElementVNode("h1", { class: "title" }, "Upgrade To Premium", -1 /* HOISTED */))
      ]),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.features, (feature, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "feature-item",
            key: index
          }, [
            _createElementVNode("h2", null, _toDisplayString(feature.title), 1 /* TEXT */),
            _createElementVNode("p", null, _toDisplayString(feature.description), 1 /* TEXT */)
          ]))
        }), 128 /* KEYED_FRAGMENT */))
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", {
          class: "plan-year plan",
          onClick: $setup.selectPlan,
          onKeydown: _withKeys($setup.selectPlan, ["enter","space"]),
          tabindex: "0",
          "data-type": "yearly",
          "data-price-id": $setup.productList[0]?.price_id
        }, [
          ($setup.selectedPlan === 'yearly')
            ? (_openBlock(), _createElementBlock("img", _hoisted_6))
            : (_openBlock(), _createElementBlock("img", _hoisted_7)),
          _createElementVNode("div", _hoisted_8, [
            _cache[2] || (_cache[2] = _createElementVNode("img", {
              src: _imports_4,
              alt: "",
              class: "save"
            }, null, -1 /* HOISTED */)),
            _cache[3] || (_cache[3] = _createElementVNode("span", { class: "save-price" }, "Save ", -1 /* HOISTED */)),
            _createElementVNode("span", _hoisted_9, _toDisplayString($setup.discountPercentage), 1 /* TEXT */)
          ]),
          _createElementVNode("div", {
            class: _normalizeClass(["subscribePlanYear", { 'dark-text': $setup.selectedPlan === 'yearly' }])
          }, [
            _cache[8] || (_cache[8] = _createElementVNode("div", { class: "plan-details" }, [
              _createElementVNode("h3", { class: "plan-title" }, "Yearly Plan"),
              _createElementVNode("small", { class: "plan-trial" }, "3-day trial for $0.1")
            ], -1 /* HOISTED */)),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("p", _hoisted_11, [
                _cache[4] || (_cache[4] = _createTextVNode(" $")),
                _createElementVNode("span", null, _toDisplayString($setup.amounts[0]), 1 /* TEXT */),
                _cache[5] || (_cache[5] = _createTextVNode("/year "))
              ]),
              _createElementVNode("small", _hoisted_12, [
                _cache[6] || (_cache[6] = _createElementVNode("span", null, "$", -1 /* HOISTED */)),
                _createElementVNode("span", null, _toDisplayString(Number($setup.amounts[2]) * 48), 1 /* TEXT */),
                _cache[7] || (_cache[7] = _createElementVNode("span", null, "/year", -1 /* HOISTED */))
              ])
            ])
          ], 2 /* CLASS */)
        ], 40 /* PROPS, NEED_HYDRATION */, _hoisted_5),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", {
            class: _normalizeClass(["month small plan", { 'dark-text': $setup.selectedPlan === 'monthly' }]),
            onClick: $setup.selectPlan,
            onKeydown: _withKeys($setup.selectPlan, ["enter","space"]),
            tabindex: "0",
            "data-type": "monthly",
            "data-price-id": $setup.productList[1]?.price_id
          }, [
            ($setup.selectedPlan === 'monthly')
              ? (_openBlock(), _createElementBlock("img", _hoisted_15))
              : (_openBlock(), _createElementBlock("img", _hoisted_16)),
            _cache[11] || (_cache[11] = _createElementVNode("h3", null, "Monthly Plan", -1 /* HOISTED */)),
            _createElementVNode("p", null, [
              _cache[9] || (_cache[9] = _createTextVNode(" $")),
              _createElementVNode("span", null, _toDisplayString($setup.amounts[1]), 1 /* TEXT */),
              _cache[10] || (_cache[10] = _createTextVNode("/month "))
            ])
          ], 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_14),
          _createElementVNode("div", {
            class: _normalizeClass(["week small plan", { 'dark-text': $setup.selectedPlan === 'weekly' }]),
            onClick: $setup.selectPlan,
            onKeydown: _withKeys($setup.selectPlan, ["enter","space"]),
            tabindex: "0",
            "data-type": "weekly",
            "data-price-id": $setup.productList[2]?.price_id
          }, [
            ($setup.selectedPlan === 'weekly')
              ? (_openBlock(), _createElementBlock("img", _hoisted_18))
              : (_openBlock(), _createElementBlock("img", _hoisted_19)),
            _cache[14] || (_cache[14] = _createElementVNode("h3", null, "Weekly Plan", -1 /* HOISTED */)),
            _createElementVNode("p", null, [
              _cache[12] || (_cache[12] = _createTextVNode(" $")),
              _createElementVNode("span", null, _toDisplayString($setup.amounts[2]), 1 /* TEXT */),
              _cache[13] || (_cache[13] = _createTextVNode("/week "))
            ])
          ], 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_17)
        ])
      ]),
      _createElementVNode("button", {
        class: "subscribe-button",
        onClick: $setup.handleSubscribe
      }, _cache[15] || (_cache[15] = [
        _createElementVNode("img", {
          src: _imports_7,
          alt: ""
        }, null, -1 /* HOISTED */),
        _createElementVNode("span", null, "SUBSCRIBE", -1 /* HOISTED */)
      ]))
    ])
  ], 64 /* STABLE_FRAGMENT */))
}