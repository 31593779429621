import { defineComponent as _defineComponent } from 'vue'
import { useUserStore } from "@/store/user";
import { computed } from "vue";
import { useRoute } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'AppTopNavMobile',
  setup(__props, { expose: __expose }) {
  __expose();

const userStore = useUserStore();
const userEmail = computed(() => userStore.user.email);
const route = useRoute();
const isLoggedIn = computed(() => !!userEmail.value);

const navItems = [
  { name: "Home", path: "/" },
  { name: "Terms of service", path: "/terms" },
  { name: "Privacy policy", path: "/privacy" },
  { name: "IOS Privacy Policy", path: "/iosprivacy" },
];

const currentRoute = computed(() => route.path);

const __returned__ = { userStore, userEmail, route, isLoggedIn, navItems, currentRoute }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})