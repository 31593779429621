import { defineComponent as _defineComponent } from 'vue'
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import CenterWrap from './CenterWrap.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'LoginCommonLayout',
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();

const isSubscribePage = computed(() => route.name === 'subscribe');


const __returned__ = { route, isSubscribePage, CenterWrap }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})