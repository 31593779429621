import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "app-top-nav" }
const _hoisted_2 = { class: "w" }
const _hoisted_3 = { class: "navbar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Router_Link = _resolveComponent("Router-Link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("nav", _hoisted_3, [
        _createElementVNode("ul", {
          class: _normalizeClass({ 'login-in': ($setup.isLoggedIn) })
        }, [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList($setup.navItems, (item, index) => {
            return _createElementVNode("li", { key: index }, [
              _createVNode(_component_Router_Link, {
                to: item.path,
                class: _normalizeClass(["nav-item", { active: $setup.currentRoute === item.path }])
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.name), 1 /* TEXT */)
                ]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to", "class"])
            ])
          }), 64 /* STABLE_FRAGMENT */)),
          _cache[0] || (_cache[0] = _createElementVNode("li", null, [
            _createElementVNode("a", { href: "mailto:support@planetlinkinc.com?subject=Customer%20Service" }, "Contact")
          ], -1 /* HOISTED */))
        ], 2 /* CLASS */)
      ])
    ])
  ]))
}