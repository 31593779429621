export const DEFAULT_RETRY_COUNT = 3;
export const DEFAULT_TIMEOUT = 6000;
export const DEFAULT_BACKOFFDELAY = 300;

export const STATUS_CODE = {
  SUCCESS: 200,
  Unauthorized: 401, // 需要重新登录
};

export const NETWORK_ERR_TYPE = {
  Unauthorized: "Unauthorized",
};
