import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, withKeys as _withKeys, withModifiers as _withModifiers, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '@/assets/images/sign/step-one.png'
import _imports_1 from '@/assets/images/sign/step-two-bright.png'
import _imports_2 from '@/assets/images/login_image/login_start.png'
import _imports_3 from '@/assets/images/login_image/login_end.png'


const _hoisted_1 = { class: "setPassword_wrap" }
const _hoisted_2 = { class: "form-container" }
const _hoisted_3 = {
  key: 0,
  class: "error-message"
}
const _hoisted_4 = { class: "email-send-button" }
const _hoisted_5 = ["onKeydown"]
const _hoisted_6 = {
  key: 0,
  src: _imports_2,
  alt: "",
  class: "confirmation-button-wrap"
}
const _hoisted_7 = {
  key: 1,
  src: _imports_3,
  alt: "",
  class: "confirmation-button-wrap"
}
const _hoisted_8 = { class: "sign_footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input_password = _resolveComponent("a-input-password")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[3] || (_cache[3] = _createStaticVNode("<span class=\"form-title\" data-v-ba409caa>REGISTER</span><br data-v-ba409caa><div class=\"step-indicator\" data-v-ba409caa><div class=\"step-one\" data-v-ba409caa><img src=\"" + _imports_0 + "\" alt=\"\" data-v-ba409caa><span class=\"step-number\" data-v-ba409caa>1</span></div><hr class=\"step-line\" data-v-ba409caa><div class=\"step-two\" data-v-ba409caa><img src=\"" + _imports_1 + "\" alt=\"\" data-v-ba409caa><span class=\"step-number\" data-v-ba409caa>2</span></div></div><p class=\"step-description\" data-v-ba409caa>Step 2 : Set password</p><p class=\"password-hint\" data-v-ba409caa>Any character with 6 or more digits</p>", 5)),
      _createVNode(_component_a_input_password, {
        value: $setup.password,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (($setup.password) = $event)),
        onFocusout: $setup.checkPassword,
        placeholder: "Password",
        class: "password"
      }, null, 8 /* PROPS */, ["value"]),
      _createCommentVNode(" 密码框 "),
      _createVNode(_component_a_input_password, {
        value: $setup.confirmPassword,
        "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => (($setup.confirmPassword) = $event)),
        onFocusout: $setup.checkPassword,
        placeholder: "Confirm Password",
        class: "password"
      }, null, 8 /* PROPS */, ["value"]),
      ($setup.passwordError)
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString($setup.passwordError), 1 /* TEXT */))
        : _createCommentVNode("v-if", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", {
        class: "confirmation-button",
        onClick: $setup.handleConfirmLogin,
        onKeydown: [
          _withKeys($setup.handleConfirmLogin, ["enter"]),
          _withKeys(_withModifiers($setup.handleConfirmLogin, ["prevent"]), ["space"])
        ],
        tabindex: "0"
      }, [
        _createVNode(_component_a_spin, { spinning: $setup.loading }, {
          default: _withCtx(() => [
            _cache[4] || (_cache[4] = _createElementVNode("span", { class: "button-text" }, "confirm & login", -1 /* HOISTED */)),
            (!$setup.canLogin)
              ? (_openBlock(), _createElementBlock("img", _hoisted_6))
              : (_openBlock(), _createElementBlock("img", _hoisted_7))
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["spinning"])
      ], 40 /* PROPS, NEED_HYDRATION */, _hoisted_5),
      _createVNode(_component_a_modal, {
        open: $setup.open,
        "onUpdate:open": _cache[2] || (_cache[2] = ($event: any) => (($setup.open) = $event)),
        title: "",
        onOk: $setup.handleOk
      }, {
        footer: _withCtx(() => _cache[5] || (_cache[5] = [])),
        default: _withCtx(() => [
          _createElementVNode("div", { class: "verification-section" }, [
            _cache[6] || (_cache[6] = _createElementVNode("h1", { class: "verification-title" }, "Email verification", -1 /* HOISTED */)),
            _cache[7] || (_cache[7] = _createElementVNode("p", { class: "verification-message" }, " An email has been sent to your inbox. Please check your email to verify. ", -1 /* HOISTED */)),
            _createElementVNode("button", {
              key: "back",
              onClick: $setup.handleCancel,
              class: "verification-action-button"
            }, " GO TO MAIL ")
          ])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["open"]),
      _createElementVNode("div", _hoisted_8, [
        _cache[8] || (_cache[8] = _createElementVNode("span", { class: "account-prompt" }, "Already have an account?", -1 /* HOISTED */)),
        _createElementVNode("a", {
          class: "login_login-in",
          href: "/mobile/login",
          onClick: _withModifiers($setup.jumpToLoginPage, ["prevent"]),
          rel: "noopener noreferrer"
        }, " Login here")
      ])
    ])
  ]))
}