import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '@/assets/images/sign/step-one.png'
import _imports_1 from '@/assets/images/sign/step-two.png'


const _hoisted_1 = { class: "register-wrap" }
const _hoisted_2 = { class: "input-wrap" }
const _hoisted_3 = {
  key: 0,
  class: "error-message-email"
}
const _hoisted_4 = { class: "bottom-wrap" }
const _hoisted_5 = { class: "login_button-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[1] || (_cache[1] = _createStaticVNode("<span class=\"sign_form-title\" data-v-9f663a7c>SIGN UP</span><br data-v-9f663a7c><div class=\"step-indicator\" data-v-9f663a7c><div class=\"step-one\" data-v-9f663a7c><img src=\"" + _imports_0 + "\" alt=\"\" data-v-9f663a7c><span class=\"step_one-num\" data-v-9f663a7c>1</span></div><hr class=\"line\" data-v-9f663a7c><div class=\"step-two\" data-v-9f663a7c><img src=\"" + _imports_1 + "\" alt=\"\" data-v-9f663a7c><span class=\"step_two-num\" data-v-9f663a7c>2</span></div></div><p class=\"step-description\" data-v-9f663a7c>Step 1: Enter your email</p>", 4)),
      _createVNode(_component_a_input, {
        value: $setup.email,
        onInput: $setup.updateEmail,
        onBlur: $setup.checkEmail,
        placeholder: "Email",
        class: "account"
      }, null, 8 /* PROPS */, ["value"]),
      ($setup.errorMessage)
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString($setup.errorMessage), 1 /* TEXT */))
        : _createCommentVNode("v-if", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", {
        class: _normalizeClass(["btn-wrap", {
          btn_start: !$setup.canSendEmail,
          btn_end: $setup.canSendEmail,
        }]),
        onClick: $setup.handleSendEmail
      }, [
        _createElementVNode("span", _hoisted_5, _toDisplayString($setup.sendText), 1 /* TEXT */)
      ], 2 /* CLASS */),
      _createElementVNode("div", { class: "sign_footer" }, [
        _cache[2] || (_cache[2] = _createElementVNode("span", { class: "account-prompt" }, "Already have an account?", -1 /* HOISTED */)),
        _createElementVNode("a", {
          class: "login_login-in",
          onClick: $setup.jumpToLoginPage,
          rel: "noopener noreferrer"
        }, " Login here")
      ])
    ]),
    _createVNode(_component_a_modal, {
      open: $setup.open,
      "onUpdate:open": _cache[0] || (_cache[0] = ($event: any) => (($setup.open) = $event)),
      title: "",
      centered: "",
      onOk: $setup.handleOk
    }, {
      footer: _withCtx(() => _cache[3] || (_cache[3] = [])),
      default: _withCtx(() => [
        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "verification-container" }, [
          _createElementVNode("h1", { class: "verification-title" }, "Email verification"),
          _createElementVNode("p", { class: "verification-message" }, " An email has been sent to your inbox. Please check your email to verify. "),
          _createCommentVNode(" <button key=\"back\" @click=\"handleCancel\" class=\"verification-button\">\n          GO TO MAIL\n        </button> ")
        ], -1 /* HOISTED */))
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["open"])
  ]))
}