import { UserStorage } from '@/storage/user';
import { User } from '@/typings/user';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useUserStore = defineStore('user', () => {
  const initUserValue: User = {
    username: '',
    email: '',
    idToken: '', // firebase idToken
    isVip: false,
    password: '',
    token: '',

    endDate: '', // 2025-12-12T08:27:41Z
    subscriptionType: '', // yearly
  };
  const user = ref<User>(initUserValue);
  function initUserFromStorage() {
    if (user.value.token) return user.value; // 说明被初始化过了
    const userData = UserStorage.getData();
    if (userData) {
      user.value = userData;
    }
    return userData;
  }

  function setUser(data: Partial<User>) {
    user.value = {
      ...user.value,
      ...data,
    };
    // 存储数据
    UserStorage.setData(user.value);
  }

  function clearToken() {
    setUser({
      token: "",
    });
  }

  function clear() {
    user.value = initUserValue;
    UserStorage.clear();
  }

  initUserFromStorage();

  return {
    user,
    setUser,
    initUserFromStorage,
    clearToken,
    clear,
  };
});
