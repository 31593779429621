<template>
  <!-- 顶部通栏 -->
  <component v-if="showNavBar" :is="currentNavComponent" />
  <!-- 主题区域 -->
  <div class="main">
    <!-- 二级路由 -->
    <router-view></router-view>
  </div>
</template>

<script setup>
import { ref, computed, onBeforeUnmount, onMounted } from "vue";
import { useRoute } from "vue-router";
import AppTopNavMobile from "@/components/AppTopNavMobile.vue";
import AppTopNavPC from "@/components/AppTopNav.vue";

// 使用路由信息
const route = useRoute();

// 计算是否显示导航栏
const showNavBar = computed(() => {
  if (typeof route.meta.requiresNavBar === "boolean") {
    return route.meta.requiresNavBar;
  }
  return true; // 默认显示，除非明确设置为 false
});

// 计算当前使用的导航组件
const currentNavComponent = ref(window.innerWidth <= 748 ? AppTopNavMobile : AppTopNavPC);

// 更新导航组件
const updateNavComponent = () => {
  currentNavComponent.value = window.innerWidth <= 748 ? AppTopNavMobile : AppTopNavPC;
};

// 添加和移除窗口大小变化的监听器
onMounted(async () => {
  // 设置全局 router 实例
  window.addEventListener("resize", updateNavComponent);
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", updateNavComponent);
});
</script>

<style lang="less">
* {
  padding: 0;
  margin: 0;
  overscroll-behavior: none;
  // overflow: hidden;
}

.main {
  width: 100%;
  height: 100%;
  // margin-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
