// baseService.ts
import { CGI_HOST } from "@/consts/mode";
import { DEFAULT_BACKOFFDELAY, DEFAULT_RETRY_COUNT, DEFAULT_TIMEOUT, STATUS_CODE } from "@/consts/network";
// eslint-disable-next-line import/no-cycle
import { useUserStore } from "@/store/user";
import { BaseResp, BaseServiceConfig } from "@/typings/api/baseServices";
import { NetworkError } from "@/utils/networkError";
import urlUtils from "@/utils/urlUtils";

export default class BaseService {
  private retryCount: number;

  private timeout: number;

  private backoffDelay: number;

  private baseUrl = CGI_HOST;

  constructor(config?: BaseServiceConfig) {
    this.retryCount = config?.retryCount || DEFAULT_RETRY_COUNT;
    this.timeout = config?.timeout || DEFAULT_TIMEOUT;
    this.backoffDelay = config?.backoffDelay || DEFAULT_BACKOFFDELAY;
  }

  private delay = (ms: number) => new Promise<void>((resolve) => {
    setTimeout(resolve, ms);
  });

  private getAuthHeader = () => {
    const userStore = useUserStore();
    const userToken = userStore.user.token;
    return {
      Authorization: `bearer ${userToken}`,
    };
  }

  private async postFormData<T>(
    url: string,
    data: FormData,
    config?: RequestInit,
    disableAuth = false,
    attempts = 0,
  ): Promise<BaseResp<T>> {
    try {
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), this.timeout);
      const authHeader = disableAuth ? {} : this.getAuthHeader();

      const response = await fetch(url, {
        method: "POST",
        body: data,
        signal: controller.signal,
        ...config,
        headers: {
          ...authHeader,
        },
      });

      clearTimeout(timeoutId);
      if (response.status === STATUS_CODE.Unauthorized) {
        // const routerUtils = new RouterUtils(routerManager.getRouter());
        // routerUtils.jumpToLoginPage();

        // 鉴权失败
        // 1. 清除 token
        const userStore = useUserStore();
        userStore.clearToken();
        // 2. 重新登录
        // FIXME: 使用 router 进行跳转
        const loginUrl = urlUtils.getLoginUrl();
        window.location.href = loginUrl;

        throw new NetworkError(STATUS_CODE.Unauthorized, "Unauthorized 401");
      }

      if (!response.ok) {
        throw new NetworkError(response.status, `HTTP error! status: ${response.status}`);
      }

      const respJson = await response.json();
      return respJson;
    } catch (error: any) {
      if (error?.statusCode === STATUS_CODE.Unauthorized) {
        throw error;
      }

      if (attempts < this.retryCount) {
        console.warn(`Attempt ${attempts + 1} failed. Retrying in ${this.backoffDelay}ms...`);
        await this.delay(this.backoffDelay);
        const respData = await this.postFormData<T>(url, data, config, disableAuth, attempts + 1);
        return respData;
      }
      throw error;
    }
  }

  public async sendFormData<T>(data: {
    cgi: string;
    payload: { [key: string]: any };
    config?: RequestInit;
    disableAuth?: boolean
  }): Promise<BaseResp<T>> {
    const { cgi, payload, config } = data;
    const url = `${this.baseUrl}/${cgi}`;
    const formData = new FormData();
    Object.entries(payload).forEach(([key, value]) => {
      formData.append(key, value);
    });

    console.log("[req]", cgi, payload, config || {});
    const respData = await this.postFormData<T>(url, formData, config, data.disableAuth);
    console.log("[resp]", cgi, respData);
    if (respData.data.token) {
      const userStore = useUserStore();
      userStore.setUser({
        token: respData.data.token,
      });
    }
    return respData;
  }
}
