export const TEST_SERVER_MODE = "TEST_SERVER_DEV";
export const PRODUCT_MODE = "PRODUCT_MODE";
export const LOCAL_DEV_MODE = "LOCAL_DEV";

export const CGI_HOST = process.env.VUE_APP_CGI_HOST;
export const MAIN_HOST = `${window.location.protocol}//${window.location.host}`;
export const SUCCESS_URL = `${MAIN_HOST}?success=1`;
export const CANCEL_URL = `${MAIN_HOST}?success=0`;
export const STRIPE_KEY = process.env.VUE_APP_STRIPE_KEY;

console.log(`CGI_HOST: ${CGI_HOST}, MAIN_HOST: ${MAIN_HOST}`);
