import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode } from "vue"
import _imports_0 from '@/assets/images/subscribe_download.png'
import _imports_1 from '@/assets/images/download_icon.png'


const _hoisted_1 = { class: "wrap" }
const _hoisted_2 = {
  key: 0,
  class: "title"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = {
  key: 0,
  class: "content-1"
}
const _hoisted_5 = { key: 1 }
const _hoisted_6 = {
  key: 2,
  class: "content-2"
}
const _hoisted_7 = {
  key: 3,
  class: "download-section"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["CenterWrap"], {
      class: _normalizeClass({ 'subscribe-style': $setup.isSubscribePage })
    }, {
      title: _withCtx(() => [
        ($setup.isSubscribePage)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, "FREEGUARD VPN"))
          : (_openBlock(), _createElementBlock("span", _hoisted_3, "FREEGUARD VPN"))
      ]),
      content: _withCtx(() => [
        ($setup.isSubscribePage)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, "Unlimited Fast & Secure Proxy"))
          : (_openBlock(), _createElementBlock("div", _hoisted_5, "Unlimited fast & secure proxy Protect your online privacy with fast and secure free VPN proxy")),
        ($setup.isSubscribePage)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, "Protect your online privacy with fast and secure free VPN proxy"))
          : _createCommentVNode("v-if", true),
        ($setup.isSubscribePage)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, _cache[0] || (_cache[0] = [
              _createElementVNode("img", {
                src: _imports_0,
                alt: "",
                class: "download_image"
              }, null, -1 /* HOISTED */),
              _createElementVNode("img", {
                src: _imports_1,
                alt: "",
                class: "download_icon"
              }, null, -1 /* HOISTED */),
              _createElementVNode("a", {
                href: "https://play.google.com/store/apps/details?id=com.freeguardvpn.app",
                class: "download-link"
              }, "Downloads", -1 /* HOISTED */)
            ])))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["class"])
  ]))
}