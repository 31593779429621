// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/images/login_image/login-left-bg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrap[data-v-65e0f175] {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  height: 100%;\n}\n.wrap .center-wrap[data-v-65e0f175] {\n  display: flex;\n  margin-top: -10vh;\n  background-color: white;\n  width: 88vw;\n  border-radius: 2.5vh;\n}\n.wrap .center-wrap .info-wrap[data-v-65e0f175] {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  flex: 1;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-position: center;\n  background-size: 100% 100%;\n  padding: 3vw;\n}\n.wrap .center-wrap .info-wrap .title[data-v-65e0f175] {\n  margin-top: -3vh;\n  margin-left: -3vw;\n  font-family: Helvetica, Helvetica;\n  font-weight: bold;\n  font-size: 3vh;\n  color: #ffffff;\n  line-height: 3.5vh;\n  text-align: left;\n  font-style: normal;\n}\n.wrap .center-wrap .info-wrap .content[data-v-65e0f175] {\n  margin-top: 3vh;\n  margin-left: -1vw;\n  font-family: Helvetica;\n  font-size: 1.6vh;\n  color: #FFFFFF;\n  line-height: 2.8vh;\n  text-align: left;\n  font-style: normal;\n}\n.wrap .center-wrap .func-wrap[data-v-65e0f175] {\n  flex: 2;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 3vw;\n}\n", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
