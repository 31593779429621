import { createApp } from 'vue';
import { VueShowdown } from 'vue-showdown';
import Antd from 'ant-design-vue';
import { createPinia } from 'pinia';

import App from './App.vue';
import router from './router';
import '@/assets/style/markdown.less';
import 'ant-design-vue/dist/reset.css';

const pinia = createPinia();

const app = createApp(App)
  .use(pinia)
  .use(router)
  .use(Antd)
  .component('VueShowdown', VueShowdown as any);

app.mount('#app');
