import { defineStore } from 'pinia';
import { ref } from 'vue';
import paymentServices from '@/services/api/paymentServices';
import { isApiWorkWell, showErrMsg } from '@/utils/network';
import { productItem } from '@/typings/api/paymentServices';
import { useUserStore } from './user';

export const useProductStore = defineStore("products", () => {
  const products = ref<productItem[]>([]); // 初始化为一个空的 GetProductsResp 对象
  const pollingInterval = ref(5000); // 默认轮询间隔时间为5秒
  let pollingTimer: any = null;

  async function getProducts() {
    try {
      const resp = await paymentServices.getProducts();
      if (isApiWorkWell(resp)) {
        products.value = resp.data.price_info || [];
      } else {
        showErrMsg(resp);
      }
    } catch (e: any) {
      showErrMsg(e.message);
    }
  }

  function stopPollingUserVipStatus() {
    if (pollingTimer) {
      clearInterval(pollingTimer);
      pollingTimer = null;
    }
  }

  // 订阅成功后更新状态
  async function updateVipStatus() {
    const resp = await paymentServices.getSubscription();
    if (isApiWorkWell(resp)) {
      const userStore = useUserStore();
      userStore.setUser({
        isVip: !!resp.data.isvip,
        endDate: resp.data.end_date || '',
        subscriptionType: resp.data.subscription_type || '',
      });
    }
  }

  async function updateStatusAfterSubSuccess() {
    await updateVipStatus();
    const userStore = useUserStore();
    if (userStore.user.isVip) { // 如果已经是 vip 就停止轮训
      stopPollingUserVipStatus();
    }
  }

  // 开始轮训
  function startPollingUserVipStatus() {
    if (pollingTimer) {
      clearInterval(pollingTimer);
    }
    pollingTimer = setInterval(updateStatusAfterSubSuccess, pollingInterval.value);
  }

  function setPollingInterval(interval: number) {
    pollingInterval.value = interval;
  }

  return {
    getProducts,
    products,
    updateVipStatus,
    updateStatusAfterSubSuccess,
    startPollingUserVipStatus,
    stopPollingUserVipStatus,
    setPollingInterval,
  };
});
