import { defineComponent as _defineComponent } from 'vue'
import { computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { firebaseService } from "@/services/firebaseService";
import { useUserStore } from "@/store/user";
import RouterUtils from "@/utils/routerUtils";
import { useLoginStore } from "@/store/login";

// 获取当前路由

export default /*@__PURE__*/_defineComponent({
  __name: 'AppTopNav',
  setup(__props, { expose: __expose }) {
  __expose();

const router = useRouter();
const route = useRoute();

// 定义响应式变量
const userStore = useUserStore();
const loginStore = useLoginStore();

const userEmail = computed(() => userStore.user.email);

const isLoggedIn = computed(() => !!userEmail.value);
const currentRoute = computed(() => route.path);

const userInitial = computed(() => (userEmail.value ? userEmail.value.charAt(0).toUpperCase() : ""));

const subscriptionType = computed(() => userStore.user.subscriptionType || "");

const endDate = computed(() => userStore.user.endDate);

const routerUtils = new RouterUtils(router);

function goToLoginPage() {
  routerUtils.jumpToLoginPage();
}

function onPopoverHide() {
  const popoverElements = document.querySelectorAll(".ant-popover");
  popoverElements.forEach((element) => {
    const el = element as HTMLElement;
    el.style.display = "none";
  });
}

// 导航项
const navItems = [
  { name: "Home", path: "/" },
  { name: "Terms of service", path: "/terms" },
  { name: "Privacy policy", path: "/privacy" },
  { name: "IOS Privacy Policy", path: "/iosprivacy" },
];

const signOut = async () => {
  const signOutSuccess = await loginStore.signOut();
  if (signOutSuccess) {
    setTimeout(() => {
      onPopoverHide();
    }, 0);
  }
};

const __returned__ = { router, route, userStore, loginStore, userEmail, isLoggedIn, currentRoute, userInitial, subscriptionType, endDate, routerUtils, goToLoginPage, onPopoverHide, navItems, signOut }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})