import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '@/assets/images/sign/step-one.png'
import _imports_1 from '@/assets/images/sign/step-two.png'
import _imports_2 from '@/assets/images/login_image/login_start.png'
import _imports_3 from '@/assets/images/login_image/login_end.png'


const _hoisted_1 = { class: "register-wrap" }
const _hoisted_2 = { class: "input-wrap" }
const _hoisted_3 = {
  key: 0,
  class: "error-message-email"
}
const _hoisted_4 = {
  key: 1,
  class: "tip-msg"
}
const _hoisted_5 = { class: "bottom-wrap" }
const _hoisted_6 = { class: "login_button-text" }
const _hoisted_7 = {
  key: 0,
  src: _imports_2,
  alt: "",
  class: "btn-wrap"
}
const _hoisted_8 = {
  key: 1,
  src: _imports_3,
  alt: "",
  class: "btn-wrap"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[1] || (_cache[1] = _createStaticVNode("<span class=\"sign_form-title\" data-v-3b21d06e>REGISTER</span><br data-v-3b21d06e><div class=\"step-indicator\" data-v-3b21d06e><div class=\"step-one\" data-v-3b21d06e><img src=\"" + _imports_0 + "\" alt=\"\" data-v-3b21d06e><span class=\"step_one-num\" data-v-3b21d06e>1</span></div><hr class=\"line\" data-v-3b21d06e><div class=\"step-two\" data-v-3b21d06e><img src=\"" + _imports_1 + "\" alt=\"\" data-v-3b21d06e><span class=\"step_two-num\" data-v-3b21d06e>2</span></div></div><p class=\"step-description\" data-v-3b21d06e>Step 1: Enter your email</p>", 4)),
      _createVNode(_component_a_input, {
        value: $setup.email,
        onInput: $setup.updateEmail,
        onBlur: $setup.checkEmail,
        placeholder: "Email",
        class: "account"
      }, null, 8 /* PROPS */, ["value"]),
      ($setup.errorMessage)
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString($setup.errorMessage), 1 /* TEXT */))
        : (_openBlock(), _createElementBlock("p", _hoisted_4, "Any character with 6 or more digits"))
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", {
        class: "register-button-wrapper",
        onClick: $setup.handleSendEmail
      }, [
        _createElementVNode("span", _hoisted_6, _toDisplayString($setup.sendText), 1 /* TEXT */),
        (!$setup.canSendEmail)
          ? (_openBlock(), _createElementBlock("img", _hoisted_7))
          : (_openBlock(), _createElementBlock("img", _hoisted_8))
      ]),
      _createElementVNode("div", { class: "sign_footer" }, [
        _cache[2] || (_cache[2] = _createElementVNode("span", { class: "account-prompt" }, "Already have an account?", -1 /* HOISTED */)),
        _createElementVNode("a", {
          class: "login_login-in",
          onClick: $setup.jumpToLoginPage,
          rel: "noopener noreferrer"
        }, " Login here")
      ])
    ]),
    _createVNode(_component_a_modal, {
      open: $setup.open,
      "onUpdate:open": _cache[0] || (_cache[0] = ($event: any) => (($setup.open) = $event)),
      title: "",
      centered: "",
      onOk: $setup.handleOk,
      class: "verification"
    }, {
      footer: _withCtx(() => _cache[3] || (_cache[3] = [])),
      default: _withCtx(() => [
        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "verification-container" }, [
          _createElementVNode("h1", { class: "verification-title" }, "Email verification"),
          _createElementVNode("p", { class: "verification-message" }, " An email has been sent to your inbox. Please check your email to verify. "),
          _createElementVNode("button", {
            key: "back",
            class: "verification-button"
          }, [
            _createElementVNode("a", { href: "mailto:support@planetlinkinc.com" }, "GO TO MAIL")
          ])
        ], -1 /* HOISTED */))
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["open"])
  ]))
}