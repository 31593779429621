import { User } from "@/typings/user";

export const userStoreKey = "__fguser__";

export class UserStorage {
  static clear() {
    window.localStorage.removeItem(userStoreKey);
  }

  static setData(user: User) {
    window.localStorage.setItem(userStoreKey, JSON.stringify(user));
  }

  static getData(): User | null {
    const data = window.localStorage.getItem(userStoreKey) || "";
    if (data) {
      return JSON.parse(data);
    }
    return null;
  }
}
