import { Modal } from "ant-design-vue";

export const openErrModal = (msg: string) => {
  Modal.error({
    title: "Error",
    content: msg,
    centered: true,
  });
};

export const openSuccessModal = (msg: string) => {
  Modal.info({
    title: "Success",
    content: msg,
    centered: true,
  });
};
