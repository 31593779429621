import { createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, withKeys as _withKeys } from "vue"
import _imports_0 from '@/assets/images/login_image/login_start.png'
import _imports_1 from '@/assets/images/login_image/login_end.png'


const _hoisted_1 = { class: "login_from" }
const _hoisted_2 = {
  key: 0,
  class: "err-msg"
}
const _hoisted_3 = {
  key: 1,
  class: "tip-msg"
}
const _hoisted_4 = {
  key: 0,
  src: _imports_0,
  alt: "",
  class: "login_wrap"
}
const _hoisted_5 = {
  key: 1,
  src: _imports_1,
  alt: "",
  class: "login_wrap"
}
const _hoisted_6 = { class: "login_footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_input_password = _resolveComponent("a-input-password")!
  const _component_a_spin = _resolveComponent("a-spin")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[3] || (_cache[3] = _createElementVNode("span", { class: "login_form-title" }, "LOGIN", -1 /* HOISTED */)),
    _createCommentVNode(" 用户名 "),
    _createVNode(_component_a_input, {
      value: $setup.email,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (($setup.email) = $event)),
      placeholder: "Account",
      class: "account"
    }, null, 8 /* PROPS */, ["value"]),
    _createCommentVNode(" 密码框 "),
    _createVNode(_component_a_input_password, {
      value: $setup.password,
      "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => (($setup.password) = $event)),
      onFocusout: $setup.checkPassword,
      placeholder: "Password",
      class: "password"
    }, null, 8 /* PROPS */, ["value"]),
    ($setup.passwordError)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString($setup.passwordError), 1 /* TEXT */))
      : (_openBlock(), _createElementBlock("p", _hoisted_3, "Any character with 6 or more digits")),
    _createElementVNode("div", {
      class: "login-button-wrapper",
      onClick: $setup.handleClick,
      onKeydown: _withKeys($setup.handleClick, ["enter"]),
      tabindex: "0"
    }, [
      _createVNode(_component_a_spin, {
        spinning: $setup.isLoading,
        class: "login-button"
      }, {
        default: _withCtx(() => [
          _cache[2] || (_cache[2] = _createElementVNode("span", { class: "login_button-text" }, "LOGIN", -1 /* HOISTED */)),
          (!$setup.canLogin)
            ? (_openBlock(), _createElementBlock("img", _hoisted_4))
            : (_openBlock(), _createElementBlock("img", _hoisted_5))
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["spinning"])
    ], 32 /* NEED_HYDRATION */),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("a", {
        onClick: $setup.handleForgetPassword,
        onKeydown: _withKeys($setup.handleForgetPassword, ["enter"]),
        tabindex: "0",
        class: "login_forgot-password"
      }, "Forgot password", 32 /* NEED_HYDRATION */),
      _createElementVNode("a", {
        onClick: $setup.jumpToRegistPage,
        onKeydown: _withKeys($setup.jumpToRegistPage, ["enter"]),
        tabindex: "0",
        class: "login_sign-in",
        rel: "noopener noreferrer"
      }, "Register", 32 /* NEED_HYDRATION */)
    ])
  ]))
}