// routerManager.ts
import { Router } from 'vue-router';

class RouterManager {
  private static instance: RouterManager;

  private router: Router | null = null;

  public static getInstance(): RouterManager {
    if (!RouterManager.instance) {
      RouterManager.instance = new RouterManager();
    }
    return RouterManager.instance;
  }

  public setRouter(router: Router) {
    this.router = router;
  }

  public getRouter(): Router {
    if (!this.router) {
      throw new Error('Router instance is not set');
    }
    return this.router;
  }
}

export default RouterManager.getInstance();
