import { defineComponent as _defineComponent } from 'vue'
import { computed } from "vue";
import CenterWrap from "./CenterWrap-mobile.vue";

// 获取当前路径

export default /*@__PURE__*/_defineComponent({
  __name: 'LoginCommonLayout-mobile',
  setup(__props, { expose: __expose }) {
  __expose();

const currentPath = window.location.pathname;

// 判断是否为 /mobile/login 页面
const isMobileLoginPage = computed(() => currentPath === "/mobile/login");

const __returned__ = { currentPath, isMobileLoginPage, CenterWrap }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})