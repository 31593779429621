<template>
  <div>
    <component :is="currentHomeComponent" />
  </div>
</template>

<script>
import { onBeforeMount, ref } from "vue";
import HomePc from "@/views/pc/Home_pc.vue";
import HomeMobile from "@/views/mobile/Home_mobile.vue";
import routerManager from "@/router/routerManager";
import { useRouter } from "vue-router";
import { useUserStore } from "@/store/user";
import { useProductStore } from "@/store/product";

export default {
  setup() {
    const detectDevice = () => {
      const isMobile = window.innerWidth <= 748;
      return isMobile ? HomeMobile : HomePc;
    };
    const currentHomeComponent = ref(detectDevice());
    const userStore = useUserStore();
    const productStore = useProductStore();

    onBeforeMount(() => {
      const url = window.location.href;
      const router = useRouter();
      routerManager.setRouter(router);
      // 初始化本地信息
      const userData = userStore.initUserFromStorage();
      // 如果有登录态，则开始更新数据
      if (userData?.token && userData?.email) {
        if (url.includes("success=1")) {
          productStore.startPollingUserVipStatus();
        } else {
          productStore.updateVipStatus();
        }
      }
    });

    return {
      currentHomeComponent,
    };
  },
};
</script>
