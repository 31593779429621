// src/services/stripeService.js
import { STRIPE_KEY } from '@/consts/mode';
import { loadStripe, RedirectToCheckoutOptions, Stripe } from '@stripe/stripe-js';

// export const publishableKey = "pk_live_51PKHniP3Zqs53dhXFYI67l9hY5Cyg3MY6fxotMmqPCiSIcMNfGUstrUloSoP60z9jVDzsRzOv6bNpdEqvVfpMyyA00qbWSjSL2";
export const publishableKey = STRIPE_KEY;

class StripeService {
  private publicKey: string;

  private stripe: Stripe | null;

  constructor() {
    this.stripe = null;
    this.publicKey = publishableKey;
  }

  async init() {
    if (!this.stripe) {
      this.stripe = await loadStripe(this.publicKey);
    }
  }

  async redirectToCheckout(opts: RedirectToCheckoutOptions) {
    await this.init();
    if (!this.stripe) return;
    const { error } = await this.stripe.redirectToCheckout(opts);

    if (error) {
      // 这里可以处理错误，比如通知用户
      throw error;
    }
  }
}

export default new StripeService();
