import { defineComponent as _defineComponent } from 'vue'
import { onMounted, onBeforeUnmount, computed, ref } from "vue";
import paymentServices from "@/services/api/paymentServices";
import stripeService from "@/services/stripeService";
import { isApiWorkWell } from "@/utils/network";
import "@/assets/style/base_pc.css";
import { useProductStore } from "@/store/product";
import { CANCEL_URL, SUCCESS_URL } from "@/consts/mode";


export default /*@__PURE__*/_defineComponent({
  __name: 'Home_pc',
  setup(__props, { expose: __expose }) {
  __expose();

const productStore = useProductStore();
const productList = computed(() => productStore.products || []);
const amounts = computed(() => productList.value.map((product) => (product.amount / 100).toFixed(2)));
const discount = ref<any>();

const handleScroll = () => {
  if (window.scrollY <= 0) {
    window.scrollTo(0, 0);
  }
};
async function discountPrice() {
  try {
    const amountsArray = amounts.value;
    if (Array.isArray(amountsArray) && amountsArray.length > 2) {
      const num1 = Number(amountsArray[0]);
      const discountValue = 1 - (num1 / (Number(amountsArray[2]) * 48));
      const discountPercentage = `${(discountValue * 100).toFixed(0)}%`;
      discount.value = discountPercentage;
    } else {
      console.error('Invalid amounts array');
    }
  } catch (error) {
    console.error('An error occurred:', error);
  }
}
async function updateDiscountText() {
  await discountPrice();
  const discountElement = document.querySelector('.discount-text');
  if (discountElement) {
    discountElement.textContent = discount.value;
  } else {
    console.error('Element with class "discount-text" not found.');
  }
}
async function startPay(priceId: string) {
  const resp = await paymentServices.stripeCheckout({
    priceId,
    cancelURL: CANCEL_URL,
    successURL: SUCCESS_URL,
  });

  if (isApiWorkWell(resp)) {
    const sessionId = resp.data.checkoutSession.id;

    await stripeService.redirectToCheckout({
      sessionId,
    });
  }
}

onMounted(async () => {
  window.addEventListener("scroll", handleScroll);
  // 初始化商品信息
  await productStore.getProducts();
  updateDiscountText();
});

onBeforeUnmount(() => {
  window.removeEventListener("scroll", handleScroll);
});

const __returned__ = { productStore, productList, amounts, discount, handleScroll, discountPrice, updateDiscountText, startPay }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})