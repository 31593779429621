import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withKeys as _withKeys, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '@/assets/images/sign/step-one.png'
import _imports_1 from '@/assets/images/sign/step-two-bright.png'


const _hoisted_1 = { class: "setPassword_wrap" }
const _hoisted_2 = { class: "form-container" }
const _hoisted_3 = {
  key: 0,
  class: "error-message"
}
const _hoisted_4 = {
  key: 1,
  class: "password-hint"
}
const _hoisted_5 = { class: "email-send-button" }
const _hoisted_6 = ["onKeydown"]
const _hoisted_7 = { class: "sign_footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input_password = _resolveComponent("a-input-password")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[3] || (_cache[3] = _createStaticVNode("<span class=\"form-title\" data-v-3ac93ce5>SIGN UP</span><br data-v-3ac93ce5><div class=\"step-indicator\" data-v-3ac93ce5><div class=\"step-one\" data-v-3ac93ce5><img src=\"" + _imports_0 + "\" alt=\"\" data-v-3ac93ce5><span class=\"step-number\" data-v-3ac93ce5>1</span></div><hr class=\"step-line\" data-v-3ac93ce5><div class=\"step-two\" data-v-3ac93ce5><img src=\"" + _imports_1 + "\" alt=\"\" data-v-3ac93ce5><span class=\"step-number\" data-v-3ac93ce5>2</span></div></div><p class=\"step-description\" data-v-3ac93ce5>Step 2 : Set password</p>", 4)),
      ($setup.passwordError)
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString($setup.passwordError), 1 /* TEXT */))
        : (_openBlock(), _createElementBlock("p", _hoisted_4, "Any character with 6 or more digits")),
      _createCommentVNode(" 密码框 "),
      _createVNode(_component_a_space, null, {
        default: _withCtx(() => [
          _createCommentVNode(" eslint-disable-next-line max-len "),
          _createVNode(_component_a_input_password, {
            value: $setup.password,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (($setup.password) = $event)),
            placeholder: "Password",
            onFocusout: $setup.checkPassword,
            class: "password-input"
          }, null, 8 /* PROPS */, ["value"])
        ]),
        _: 1 /* STABLE */
      }),
      _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1 /* HOISTED */)),
      _createVNode(_component_a_space, null, {
        default: _withCtx(() => [
          _createCommentVNode(" eslint-disable-next-line max-len "),
          _createVNode(_component_a_input_password, {
            value: $setup.confirmPassword,
            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => (($setup.confirmPassword) = $event)),
            placeholder: "Confirm Password",
            onFocusout: $setup.checkPassword,
            class: "password-confirm-input"
          }, null, 8 /* PROPS */, ["value"])
        ]),
        _: 1 /* STABLE */
      }),
      _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1 /* HOISTED */))
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", {
        class: _normalizeClass(["confirmation-button", {
        login_button_start: !$setup.canLogin,
        login_button_end: $setup.canLogin,
      }]),
        onClick: $setup.confirmLogin,
        onKeydown: [
          _withKeys($setup.confirmLogin, ["enter"]),
          _withKeys(_withModifiers($setup.confirmLogin, ["prevent"]), ["space"])
        ],
        tabindex: "0"
      }, _cache[6] || (_cache[6] = [
        _createElementVNode("span", { class: "button-text" }, "confirm & login", -1 /* HOISTED */)
      ]), 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_6),
      _createVNode(_component_a_modal, {
        open: $setup.open,
        "onUpdate:open": _cache[2] || (_cache[2] = ($event: any) => (($setup.open) = $event)),
        title: "",
        onOk: $setup.handleOk
      }, {
        footer: _withCtx(() => _cache[7] || (_cache[7] = [])),
        default: _withCtx(() => [
          _createElementVNode("div", { class: "verification-section" }, [
            _cache[8] || (_cache[8] = _createElementVNode("h1", { class: "verification-title" }, "Email verification", -1 /* HOISTED */)),
            _cache[9] || (_cache[9] = _createElementVNode("p", { class: "verification-message" }, " An email has been sent to your inbox. Please check your email to verify. ", -1 /* HOISTED */)),
            _createElementVNode("button", {
              key: "back",
              onClick: $setup.handleCancel,
              class: "verification-action-button"
            }, " GO TO MAIL ")
          ])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["open"]),
      _createElementVNode("div", _hoisted_7, [
        _cache[10] || (_cache[10] = _createElementVNode("span", { class: "account-prompt" }, "Already have an account?", -1 /* HOISTED */)),
        _createElementVNode("a", {
          class: "login_login-in",
          href: "/account/login",
          onClick: _withModifiers($setup.jumpToLoginPage, ["prevent"]),
          rel: "noopener noreferrer"
        }, " Login here")
      ])
    ])
  ]))
}