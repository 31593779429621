import { defineComponent as _defineComponent } from 'vue'
import { ref } from 'vue';
import termsContent from '@/assets/text/terms';


export default /*@__PURE__*/_defineComponent({
  __name: 'TermsOfService',
  setup(__props, { expose: __expose }) {
  __expose();

const markdown = ref(termsContent);

const __returned__ = { markdown }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})