import { createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "login_form" }
const _hoisted_2 = {
  key: 0,
  class: "err-msg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_input_password = _resolveComponent("a-input-password")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[3] || (_cache[3] = _createElementVNode("span", { class: "login_form-title" }, "LOGIN", -1 /* HOISTED */)),
      _createCommentVNode(" 用户名 "),
      _createVNode(_component_a_input, {
        value: $setup.email,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (($setup.email) = $event)),
        placeholder: "Account",
        class: "account"
      }, null, 8 /* PROPS */, ["value"]),
      _createCommentVNode(" 密码框 "),
      _createVNode(_component_a_input_password, {
        value: $setup.password,
        "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => (($setup.password) = $event)),
        onFocusout: $setup.checkPassword,
        placeholder: "Password",
        class: "password"
      }, null, 8 /* PROPS */, ["value"]),
      ($setup.passwordError)
        ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString($setup.passwordError), 1 /* TEXT */))
        : _createCommentVNode("v-if", true),
      _createElementVNode("div", {
        class: _normalizeClass(["login_wrap", {
        login_button_start: !$setup.canLogin,
        login_button_end: $setup.canLogin,
      }]),
        onClick: $setup.navigateToHome
      }, _cache[2] || (_cache[2] = [
        _createElementVNode("span", { class: "login_button-text" }, "LOGIN", -1 /* HOISTED */)
      ]), 2 /* CLASS */),
      _createElementVNode("div", { class: "login_footer" }, [
        _createElementVNode("a", {
          onClick: $setup.handleForgetPassword,
          class: "login_forgot-password"
        }, "Forgot password"),
        _createElementVNode("a", {
          onClick: $setup.jumpToRegistPage,
          class: "login_sign-in",
          rel: "noopener noreferrer"
        }, "Register")
      ])
    ])
  ]))
}