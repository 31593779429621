import Terms from '@/views/category/TermsOfService.vue';
import { RouteRecordRaw } from 'vue-router';
import LoginComponent from '@/views/login/login-pc/LoginComponent.vue';
import LoginComponentMobile from '@/views/login/login-mobile/LoginComponent-mobile.vue';
import RegisterComponent from '@/views/login/login-pc/RegisterComponent.vue';
import RegisterComponentMobile from '@/views/login/login-mobile/RegisterComponent-mobile.vue';
import SetPassWord from '@/views/login/login-pc/SetPassWord.vue';
import SetPassWordMobile from '@/views/login/login-mobile/SetPassWord-mobile.vue';
import Subscribe from '@/views/login/login-pc/Subscribe.vue';
import SubscriptionOptions from '@/views/login/login-mobile/SubscriptionOptions.vue';
import Layout from '../views/MainLayout.vue';
import Home from '../views/HomeView.vue';
import Con from '../views/category/ContactUs.vue';
import PrivacyPolicy from '../views/category/PrivacyPolicy.vue';
import IOSPrivacyPolicy from '../views/category/IOSPrivacyPolicy.vue';
import LoginLayout from '../views/login/common-pc/LoginCommonLayout.vue';
import LoginLayoutMobile from '../views/login/common-mobile/LoginCommonLayout-mobile.vue';

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Layout',
    component: Layout,
    children: [
      {
        path: '/',
        component: Home, // 动态选择组件
        meta: { transition: '' },
      },
      {
        path: '/terms',
        component: Terms,
        meta: { transition: '' },
      },
      {
        path: '/con',
        component: Con,
      },
      {
        path: '/privacy',
        component: PrivacyPolicy,
        meta: { transition: '' },
      },
      {
        path: '/iosprivacy',
        component: IOSPrivacyPolicy,
        meta: { transition: '' },
      },
      {
        path: 'account',
        component: LoginLayout,
        meta: { requiresNavBar: false },
        children: [
          {
            path: 'login',
            component: LoginComponent,
          },
          {
            path: 'register',
            component: RegisterComponent,
          },
          {
            path: 'password',
            component: SetPassWord,
          },
          {
            path: 'subscribe',
            name: 'subscribe',
            component: Subscribe,
          },
        ],
      },
      {
        path: 'mobile',
        component: LoginLayoutMobile,
        meta: { requiresNavBar: false },
        children: [
          {
            path: 'login',
            component: LoginComponentMobile,
          },
          {
            path: 'register',
            component: RegisterComponentMobile,
          },
          {
            path: 'setpassword',
            component: SetPassWordMobile,
          },
        ],
      },
      {
        path: 'subscribe',
        component: SubscriptionOptions,
        meta: { requiresNavBar: false },
      },
    ],
  },
];
