<template>
  <div class="login_from">
    <span class="login_form-title">LOGIN</span>
    <!-- 用户名 -->
    <a-input v-model:value="email" placeholder="Account" class="account" />
    <!-- 密码框 -->
    <a-input-password v-model:value="password" @focusout="checkPassword" placeholder="Password" class="password" />
    <p v-if="passwordError" class="err-msg">{{ passwordError }}</p>
    <p v-else class="tip-msg">Any character with 6 or more digits</p>
    <div class="login-button-wrapper" @click="handleClick" @keydown.enter="handleClick" tabindex="0">
      <a-spin :spinning="isLoading" class="login-button">
        <span class="login_button-text">LOGIN</span>
        <img src="@/assets/images/login_image/login_start.png" alt="" class="login_wrap" v-if="!canLogin">
        <img src="@/assets/images/login_image/login_end.png" alt="" class="login_wrap" v-else>
      </a-spin>
    </div>
    <div class="login_footer">
      <a @click="handleForgetPassword" @keydown.enter="handleForgetPassword" tabindex="0"
        class="login_forgot-password">Forgot password</a>
      <a @click="jumpToRegistPage" @keydown.enter="jumpToRegistPage" tabindex="0" class="login_sign-in"
        rel="noopener noreferrer">Register</a>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from "vue";
import { isPasswordStrong, isValidEmail } from "@/utils/index";
import { useRouter } from "vue-router";
import { firebaseService } from "@/services/firebaseService";
import { openErrModal } from "@/utils/ui";
import RouterUtils from "@/utils/routerUtils";
import { useLoginStore } from "@/store/login";
import { Spin } from 'ant-design-vue';

const password = ref("");
const passwordError = ref("");
const router = useRouter();
const loginStore = useLoginStore();

const email = ref<string>("");
const routerUtils = new RouterUtils(router);

const isEmailValid = computed(() => isValidEmail(email.value));
const canLogin = computed(() => email.value && password.value && !passwordError.value);
const isLoading = ref(false);

const jumpToRegistPage = () => {
  routerUtils.jumpToRegister();
};

const handleForgetPassword = () => {
  if (!isEmailValid.value) {
    openErrModal("Email Invalid.");
  } else {
    firebaseService.resetPassword(email.value);
  }
};

// 实时获取密码
watch(password, (newPassword) => {
  if (!isPasswordStrong(newPassword)) {
    passwordError.value = "Password invalid.";
  } else {
    passwordError.value = "";
  }
});

// 检查密码强度
const checkPassword = () => {
  if (!isPasswordStrong(password.value)) {
    passwordError.value = "Password invalid.";
  } else {
    passwordError.value = "";
  }
};

// 登录成功跳转home页
const navigateToSubscribe = async () => {
  if (!passwordError.value && email.value && password.value) {
    const loginSuccess = await loginStore.login(email.value, password.value);
    if (loginSuccess) {
      routerUtils.jumpToSubscribe();
    }
  }
};
const handleClick = async () => {
  if (isLoading.value) return;

  isLoading.value = true;
  try {
    await navigateToSubscribe();
  } finally {
    isLoading.value = false;
  }
};
</script>

<style>
.login_from {
  margin-top: 2vh;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.err-msg {
  margin-top: 1vh;
  color: red;
  font-size: 3vw;
  /* margin-left: 1vw; */
  transition: opacity 0.3s ease;
  display: flex;
  justify-content: center;
  word-break: normal;
}

.tip-msg {
  margin-top: 1vh;
  color: #9297b3;
  font-size: 3vw;
  /* margin-left: 1vw !important; */
}

.login_form-title {
  margin-top: 1vh;
  display: flex;
  justify-content: center;
  font-family: Helvetica, Helvetica;
  font-weight: bold;
  font-size: 3.5vh;
  line-height: 4vh;
  color: #180b38;
  text-align: left;
  font-style: normal;
  margin-bottom: 3vh;
}

.account,
.password {
  margin: 1vw 0;
  width: 70vw;
  height: 6vh;
  border-radius: 8vw;
  font-size: 2vh;
  font-weight: bolder;
  padding: 0 3vw;
  background-color: #e5e7f4;
  display: flex;
  align-items: center;
}

.password {
  margin-top: 1.5vh;
}

.password input {
  border-radius: 5vw;
  height: 7vh;
  font-weight: bold;
  background-color: transparent !important;
}

.login-button-wrapper {
  position: relative;
  margin-top: 5vh;
  cursor: pointer;
}

.ant-spin-container {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.login_button-text {
  color: rgb(230, 219, 253);
  font-size: 2.2vh;
  font-weight: bold;
  font-family: Helvetica, Helvetica;
  z-index: 100;
}

.login_wrap {
  position: absolute;
  border-radius: 8vw;
  width: 70vw;
  height: 6vh;
  -o-object-fit: cover;
  object-fit: cover;
}

.login_footer {
  margin-top: 3vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login_forgot-password {
  font-size: 1.5vh;
  cursor: pointer;
  text-decoration: none;
}

.login_sign-in {
  margin-left: 30vw;
  font-size: 1.5vh;
  color: #7555f2;
  text-decoration: underline;
  cursor: pointer;
}

::v-deep.password .ant-input {
  background-color: #e5e7f4 !important;
  font-weight: bold;
}
</style>
