// apiService.ts

import { getAuth } from 'firebase/auth';

// 获取当前用户的 ID token
export async function getIdToken(): Promise<string | null> {
  const auth = getAuth();
  const user = auth.currentUser;

  if (user) {
    const idToken = await user.getIdToken();
    console.log("user idToken: ", idToken);
    return idToken;
  }
  console.log("idToken: no login");
  return null;
}

// 通用的请求函数
async function makeAuthenticatedRequest(endpoint: string, options: RequestInit = {}): Promise<any> {
  const idToken = await getIdToken();

  if (!idToken) {
    throw new Error('用户未登录');
  }

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${idToken}`,
    ...options.headers,
  };

  const response = await fetch(endpoint, {
    ...options,
    headers,
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || '请求失败');
  }

  return response.json();
}
