<template>
  <div class="setPassword_wrap">
    <div class="form-container">
      <span class="form-title">SIGN UP</span><br />
      <div class="step-indicator">
        <div class="step-one">
          <img src="@/assets/images/sign/step-one.png" alt="" />
          <span class="step-number">1</span>
        </div>
        <hr class="step-line" />
        <div class="step-two">
          <img src="@/assets/images/sign/step-two-bright.png" alt="" />
          <span class="step-number">2</span>
        </div>
      </div>
      <p class="step-description">Step 2 : Set password</p>
      <p v-if="passwordError" class="error-message">{{ passwordError }}</p>
      <p v-else class="password-hint">Any character with 6 or more digits</p>
      <!-- 密码框 -->
      <a-space>
        <!-- eslint-disable-next-line max-len -->
        <a-input-password v-model:value="password" placeholder="Password" @focusout="checkPassword"
          class="password-input" />
      </a-space>
      <br />
      <a-space>
        <!-- eslint-disable-next-line max-len -->
        <a-input-password v-model:value="confirmPassword" placeholder="Confirm Password" @focusout="checkPassword"
          class="password-confirm-input" />
      </a-space>
      <br />
    </div>
    <div class="email-send-button">
      <div class="confirmation-button" :class="{
        login_button_start: !canLogin,
        login_button_end: canLogin,
      }" @click="confirmLogin" @keydown.enter="confirmLogin" @keydown.space.prevent="confirmLogin" tabindex="0">
        <span class="button-text">confirm & login</span>
      </div>
      <a-modal v-model:open="open" title="" @ok="handleOk">
        <template #footer> </template>
        <div class="verification-section">
          <h1 class="verification-title">Email verification</h1>
          <p class="verification-message">
            An email has been sent to your inbox. Please check your email to verify.
          </p>
          <button key="back" @click="handleCancel" class="verification-action-button">
            GO TO MAIL
          </button>
        </div>
      </a-modal>
      <div class="sign_footer">
        <span class="account-prompt">Already have an account?</span>
        <a class="login_login-in" href="/account/login" @click.prevent="jumpToLoginPage" rel="noopener noreferrer">
          Login here</a>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed, ref } from "vue";
import { isPasswordStrong } from "@/utils/index";
import { firebaseService } from "@/services/firebaseService";
import { useRouter } from "vue-router";
import { openErrModal } from "@/utils/ui";
import RouterUtils from "@/utils/routerUtils";
import { useUserStore } from "@/store/user";

const router = useRouter();
const userStore = useUserStore();

const loading = ref<boolean>(false);
const open = ref<boolean>(false);
const password = ref("");
const confirmPassword = ref("");
const passwordError = ref("");
const canLogin = computed(() => passwordError.value === "");
const routerUtils = new RouterUtils(router);

const handleOk = () => {
  loading.value = true;
  setTimeout(() => {
    loading.value = false;
    open.value = false;
  }, 2000);
};

const handleCancel = () => {
  open.value = false;
};

const jumpToLoginPage = () => {
  routerUtils.jumpToLoginPage();
};

const checkPassword = () => {
  if (!isPasswordStrong(password.value)) {
    passwordError.value = "Password must be at least 8 characters long and contain two of the following: letters, numbers, special characters.";
  } else if (password.value !== confirmPassword.value) {
    passwordError.value = "Passwords do not match.";
  } else {
    passwordError.value = "";
  }
};

const confirmLogin = async () => {
  const userEmail = userStore.user.email;
  if (!userEmail) {
    openErrModal("email invalid");
  }
  const result = await firebaseService.setPassword(userEmail, confirmPassword.value);
  if (result.success) {
    // 跳转到首页
    routerUtils.jumpToMainPage();
  }
};
</script>
<style scoped>
.login_button_start {
  background-image: url("@/assets/images/login_image/login_start.png") !important;
}

.login_button_end {
  background-image: url("@/assets/images/login_image/login_end.png") !important;
}

.setPassword_wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
}

.form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form-title {
  font-size: 2vw;
  font-weight: bolder;
  z-index: 3;
}

/* 步骤条 */
.step-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
}

.step-one,
.step-two {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: white;
}

.step-one img {
  /* background: linear-gradient(45deg, #6a11cb, #2575fc); */
  width: 50px;
  height: 50px;
}

.step-two img {
  width: 50px;
  height: 50px;
}

.step-number {
  position: absolute;
  font-size: 16px;
}

.step-line {
  flex-grow: 1;
  height: 0;
  border: none;
  border-top: 5px dotted #8359ff;
  margin: 0 1vw;
}

.step-description {
  margin: 1vw 0 0 0;
  color: #6d52d9;
  font-size: 1.5vw;
  font-weight: bold;
}

.password-hint {
  color: #9297b3;
  margin: 1vw 0 0 0;
  font-size: 1.5vw;
}

/* 输入框 */
.password-input {
  margin: 1vw 0 0 0;
  width: 25vw;
  height: 7vh;
  background-color: #e5e7f4;
  border-radius: 5vw;
  font-size: 1vw;
  font-weight: bolder;
}

::v-deep.password-input .ant-input {
  background-color: #e5e7f4 !important;
  font-weight: bold;
  box-shadow: none;
  border-radius: 50px !important;
  padding: 0 0 0 5% !important;
}

.password-confirm-input {
  width: 25vw;
  height: 7vh;
  font-size: 1vw !important;
  font-weight: bolder;
  background-color: #e5e7f4;
  border-radius: 50px;
  font-size: 20px;
  font-weight: bolder;
}

::v-deep.password-confirm-input .ant-input {
  background-color: #e5e7f4;
  font-weight: bold;
  box-shadow: none;
  border-radius: 25px !important;
  padding: 0 0 0 5% !important;
}

:where(.css-dev-only-do-not-override-1p3hq3p).ant-input-affix-wrapper>input.ant-input {
  background-color: #e5e7f4 !important;
}

/* 发送邮件按钮 */
.confirmation-image {
  display: block;
  width: 25vw;
  height: 7vh;
}

/* 垂直居中对齐 */
.email-send-button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.confirmation-button {
  position: relative;
  display: inline-block;
  width: 25vw;
  height: 7vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  cursor: pointer;
}

.button-content {
  position: relative;
}

.button-text {
  position: absolute;
  top: 28%;
  left: 50%;
  transform: translateX(-50%);
  color: rgb(230, 219, 253);
  font-size: 1.5vw;
  font-weight: bold;
  text-align: center;
}

:where(.css-dev-only-do-not-override-1p3hq3p).ant-btn-primary {
  color: #fff !important;
  background-color: unset !important;
  box-shadow: none !important;
}

:where(.css-dev-only-do-not-override-1p3hq3p).ant-btn-primary:disabled {
  border-color: transparent !important;
}

.error-message {
  color: red;
  font-size: 1vw;
  margin-top: 1vw;
  margin-left: 1.5vw;
  width: 25vw;
  z-index: 4;
}

/* 对话框 */
:where(.css-dev-only-do-not-override-1p3hq3p).ant-modal .ant-modal-content {
  border-radius: 40px;
  width: 400px;
  height: 350px;
}

.verification-section {
  font-family: Arial, sans-serif;
  text-align: center;
  padding: 50px 10px;
}

.verification-title {
  /* font-size: 2em; */
  font-size: 35px;
  font-weight: bold;
  background: linear-gradient(to right, #237fff, #ed5aff);
  -webkit-background-clip: text;
  color: transparent;
}

.verification-message {
  /* font-size: 1.2em; */
  font-size: 18px;
  color: #605086;
  margin: 20px 0;
}

.verification-action-button {
  position: absolute;
  /* font-size: 1em; */
  font-size: 18px;
  font-weight: bold;
  color: white;
  background: linear-gradient(to right, #ffb113, #9f3fff, #4393ff);
  border: none;
  border-radius: 30px;
  width: 300px;
  height: 45px;
  top: 250px;
  left: 25px;
  cursor: pointer;
}

/* 提示信息 */
.sign_footer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.account-prompt {
  color: #180b38;
  font-size: 18px;
}

.login_login-in {
  color: #7555f2;
  text-decoration: underline;
  font-size: 18px;
}

.sign_contact {
  font-size: 16px;
}

.sign_contact a {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #e7e4ff;
}
</style>
