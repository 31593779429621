import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"
import _imports_0 from '@/assets/images/return_key.png'


const _hoisted_1 = { class: "wrap" }
const _hoisted_2 = { class: "header-container" }
const _hoisted_3 = { class: "header" }
const _hoisted_4 = {
  key: 0,
  src: _imports_0,
  alt: "",
  class: "return_key"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_link, { to: "/" }, {
          default: _withCtx(() => [
            ($setup.isMobileLoginPage)
              ? (_openBlock(), _createElementBlock("img", _hoisted_4))
              : _createCommentVNode("v-if", true)
          ]),
          _: 1 /* STABLE */
        }),
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "header-title" }, "FREEGUARD VPN", -1 /* HOISTED */)),
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "header-subtitle" }, "Unlimited Fast & Secure Proxy", -1 /* HOISTED */))
      ])
    ]),
    _createVNode($setup["CenterWrap"])
  ]))
}