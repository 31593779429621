import { defineComponent as _defineComponent } from 'vue'
import { computed, ref, watch } from "vue";
import { isPasswordStrong, isValidEmail } from "@/utils/index";
import { useRouter } from "vue-router";
import { firebaseService } from "@/services/firebaseService";
import { openErrModal } from "@/utils/ui";
import RouterUtils from "@/utils/routerUtils";
import { useLoginStore } from "@/store/login";


export default /*@__PURE__*/_defineComponent({
  __name: 'LoginComponent',
  setup(__props, { expose: __expose }) {
  __expose();

const password = ref("");
const passwordError = ref("");
const router = useRouter();
const loginStore = useLoginStore();
const email = ref<string>("");

const isEmailValid = computed(() => isValidEmail(email.value));
const canLogin = computed(() => email.value && password.value && !passwordError.value);
const routerUtils = new RouterUtils(router);

const jumpToRegistPage = () => {
  routerUtils.jumpToRegister();
};

const handleForgetPassword = () => {
  if (!isEmailValid.value) {
    openErrModal("Email Invalid.");
  } else {
    firebaseService.resetPassword(email.value);
  }
};

// 实时获取密码
watch(password, (newPassword) => {
  if (!isPasswordStrong(newPassword)) {
    passwordError.value = "Password invalid.";
  } else {
    passwordError.value = "";
  }
});

// 检查密码强度
const checkPassword = () => {
  if (!isPasswordStrong(password.value)) {
    passwordError.value = "Password invalid.";
  } else {
    passwordError.value = "";
  }
};

// 登录成功跳转home页
const navigateToHome = async () => {
  if (!passwordError.value && email.value && password.value) {
    const loginSuccess = await loginStore.login(email.value, password.value);
    if (loginSuccess) {
      routerUtils.jumpToMainPage();
    }
  }
};

const __returned__ = { password, passwordError, router, loginStore, email, isEmailValid, canLogin, routerUtils, jumpToRegistPage, handleForgetPassword, checkPassword, navigateToHome }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})