<template>
  <!-- <AppTopNav /> -->
  <div class="main-container">
    <div class="app-header-nav" id="part_1">
      <img src="@/assets/images/star.jpg" alt="" />
      <div class="tab-content">
        <div class="tab">
          <a href="#part_1" class="tablinks_1">part_1</a><br />
          <a href="#part_2" class="tablinks_2">part_2</a><br />
          <a href="#part_3" class="tablinks_3">part_3</a><br />
          <a href="#part_4" class="tablinks_4">part_4</a>
        </div>
        <div class="vpn-intro" id="part_2">
          <span class="wrapper title">FREEGUARD VPN</span>
          <span class="wrapper subtitle">Unlimited Fast & Secure Proxy</span>
          <span class="wrapper description">Protect your online privacy with fast and secure free VPN proxy</span>
          <div class="download-section">
            <img src="@/assets/images/download_image.png" alt="" class="download_image" />
            <img src="@/assets/images/download_icon.png" alt="" class="download_icon" />
            <a href="https://play.google.com/store/apps/details?id=com.freeguardvpn.app"
              class="download-link">Downloads</a>
          </div>
        </div>
      </div>
      <div class="subscription-section">
        <div class="premium-features">
          <div class="feature speed-boost">
            <span class="feature-title">Upgrade To Premium</span>
            <h4 class="feature-heading">Speed Boost</h4>
            <span class="wrapper feature-description">
              Experience over 4x faster speeds, depending on local network conditions.
            </span>
          </div>
          <div class="feature expanded-nodes1">
            <h4 class="feature-heading">Expanded Nodes</h4>
            <span class="wrapper feature-description">
              Access a wider range of geographic locations.
            </span>
          </div>
          <div class="feature expanded-nodes2">
            <h4 class="feature-heading">Enhanced Privacy</h4>
            <span class="wrapper feature-description">
              Enhance privacy and traffic camouflage using virtual NICs and proprietary
              protocols.
            </span>
          </div>
          <div class="feature enhanced-privacy">
            <h4 class="feature-heading">Broader App Support</h4>
            <span class="wrapper feature-description">
              Enjoy a richer selection of apps and websites, including ChatGPT, Netflix,
              and more.
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="pricing-background">
      <div class="subscription-options">
        <div class="subscription_image">
          <div class="yearly-plan">
            <img src="@/assets/images/year.png" alt="" class="year_img" />
            <img src="@/assets/images/save-bg.png" alt="" class="discount-badge" />
            <span class="discount-style">Save </span>
            <span class="discount-text-style discount-text"></span>
            <div class="plan-details-year">
              <h2>YEARLY PLAN</h2>
              <p class="trial-info">3-day trial for $0.1</p>
              <div class="price_year">
                <div class="price-year-save">
                  <span class="currency-symbol-year">$</span>
                  <span id="price">{{ amounts[0] }}</span>
                  <span class="price-duration-year">/year</span>
                </div>
                <div class="old-price">
                  <span>$</span>
                  <span>{{ Number(amounts[2]) * 48 }}</span>
                  <span>/year</span>
                </div>
              </div>
              <button class="button_year button" @click="startPay(productList[0].price_id)">
                Activate now
              </button>
            </div>
          </div>
          <div class="monthly-plan">
            <img src="@/assets/images/month.png" alt="" class="month_img" />
            <div class="plan-details-month">
              <h2>MONTHLY PLAN</h2>
              <div class="price_month">
                <span class="current-month">$</span>
                <span class="amount">{{ amounts[1] }}</span>
                <span class="duration">/month</span>
              </div>
              <button class="button_month button" @click="startPay(productList[1].price_id)">
                Activate now
              </button>
            </div>
          </div>
          <div class="weekly-plan">
            <img src="@/assets/images/week.png" alt="" class="week_img" />
            <div class="plan-details-week">
              <h2>WEEKLY PLAN</h2>
              <div class="price_week">
                <span class="current-month">$</span>
                <span class="amount">{{ amounts[2] }}</span>
                <span class="duration">/week</span>
              </div>
              <button class="button_month button" @click="startPay(productList[2].price_id)">
                Activate now
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="features-section" id="part_3">
        <div class="features-content">
          <span class="wrapper feature-title-part3">FEATURES INTRO</span>
          <span class="wrapper feature-item-part3">Protect privacy</span>
          <span class="wrapper feature-description-part3">
            Help you protect your online privacy, especially when connecting to public
            Wi-Fi networks.</span>
          <span class="wrapper feature-title2-part3">Bypass geographic restrictions</span>
          <span class="wrapper feature-description2-part3">Allow you to bypass geographic restrictions and access
            content and websites
            that may be blocked in your respective regions.</span>
          <span class="wrapper feature-title3-part3">Improve network speed</span>
          <span class="wrapper feature-description3-part3">Some of you may need improve network speeds, especially when
            your internet
            connections are slow. That’s exactly what FreeGuard VPN is capable of.</span>
        </div>
        <img src="@/assets/images/image1.png" alt="" class="feature-image" />
      </div>
      <div class="security-section">
        <img src="@/assets/images/image2.png" alt="" class="security-image" />
        <div class="security-content">
          <span class="wrapper feature-item-security">Improve online security</span>
          <span class="wrapper feature-description-security">Help you protect your online privacy, especially when
            connecting to public
            Wi-Fi networks.</span>
          <span class="wrapper feature-item2-security">Download and share files</span>
          <span class="wrapper feature-description2-security">Can allow you to download and share files anonymously,
            especially when using
            P2P sharing.</span>
          <span class="wrapper feature-item3-security">Access international services</span>
          <span class="wrapper feature-description3-security">
            Can enable you to access international streaming services and websites, such
            as Netflix and Hulu.</span>
          <span class="wrapper feature-item4-security" id="part_2">Bypass website censorship</span>
          <span class="wrapper feature-description4-security" id="part_3">Can help you from countries bypass website
            censorship.</span>
        </div>
      </div>
      <div class="comments-section">
        <div class="comments-background">
          <img src="@/assets/images/bg2.png" alt="" />
          <div class="comments-content">
            <span class="comments-title" id="part_4">USER COMMENTS</span>
            <div class="user-comment1">
              <div class="user-icon">
                <div class="icon-circle1">
                  <h3>C</h3>
                </div>
              </div>
              <div class="rectangle">
                <p>Chloe ***</p>
                <div class="comment-text">
                  This app is pretty useful and stable. I would like to recommend it to my
                  family and friends. Please keep going to improve it!
                </div>
                <img src="@/assets/images/star_1.png" alt="" />
              </div>
            </div>
            <div class="user-comment2">
              <div class="user-icon">
                <div class="icon-circle2">
                  <h3>M</h3>
                </div>
              </div>
              <div class="rectangle">
                <p>Mohammad ***</p>
                <div class="comment-text">Best apps. Very easy to connect.</div>
                <img src="@/assets/images/star_1.png" alt="" />
              </div>
            </div>
            <div class="user-comment3">
              <div class="user-icon">
                <div class="icon-circle3">
                  <h3>G</h3>
                </div>
              </div>
              <div class="rectangle">
                <p>Gregory ***</p>
                <div class="comment-text">
                  It works I can for sure tell you that much it does get the job done
                </div>
                <img src="@/assets/images/star_1.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="app-end-nav">
          <a href="mailto:support@planetlinkinc.com">
            If you want to cooperate with us, you can contact us by email</a><br />
          <a href="mailto:support@planetlinkinc.com">support@planetlinkinc.com</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, onBeforeUnmount, computed, ref } from "vue";
import paymentServices from "@/services/api/paymentServices";
import stripeService from "@/services/stripeService";
import { isApiWorkWell } from "@/utils/network";
import "@/assets/style/base_pc.css";
import { useProductStore } from "@/store/product";
import { CANCEL_URL, SUCCESS_URL } from "@/consts/mode";

const productStore = useProductStore();
const productList = computed(() => productStore.products || []);
const amounts = computed(() => productList.value.map((product) => (product.amount / 100).toFixed(2)));
const discount = ref<any>();

const handleScroll = () => {
  if (window.scrollY <= 0) {
    window.scrollTo(0, 0);
  }
};
async function discountPrice() {
  try {
    const amountsArray = amounts.value;
    if (Array.isArray(amountsArray) && amountsArray.length > 2) {
      const num1 = Number(amountsArray[0]);
      const discountValue = 1 - (num1 / (Number(amountsArray[2]) * 48));
      const discountPercentage = `${(discountValue * 100).toFixed(0)}%`;
      discount.value = discountPercentage;
    } else {
      console.error('Invalid amounts array');
    }
  } catch (error) {
    console.error('An error occurred:', error);
  }
}
async function updateDiscountText() {
  await discountPrice();
  const discountElement = document.querySelector('.discount-text');
  if (discountElement) {
    discountElement.textContent = discount.value;
  } else {
    console.error('Element with class "discount-text" not found.');
  }
}
async function startPay(priceId: string) {
  const resp = await paymentServices.stripeCheckout({
    priceId,
    cancelURL: CANCEL_URL,
    successURL: SUCCESS_URL,
  });

  if (isApiWorkWell(resp)) {
    const sessionId = resp.data.checkoutSession.id;

    await stripeService.redirectToCheckout({
      sessionId,
    });
  }
}

onMounted(async () => {
  window.addEventListener("scroll", handleScroll);
  // 初始化商品信息
  await productStore.getProducts();
  updateDiscountText();
});

onBeforeUnmount(() => {
  window.removeEventListener("scroll", handleScroll);
});
</script>

<style scoped>
/* 添加样式 */
</style>

<style lang="less" scoped>
.main-container {
  max-width: 1400px;
  margin: 0 auto;
  margin-top: 60px;
}

img {
  float: left;
  width: 100%;
  height: 90%;
  object-fit: cover;
}

.app-header-nav {
  display: flex;
  justify-content: space-between;
}

.tab {
  float: left;
  // top: 16%;
  // left: -4%;
  margin: 130px 0 0 -1320px;
  // margin: 7vw 0 0 -92vw;
  z-index: 2;
}

.tab a {
  outline: none;
  text-decoration: none;
  display: inline-block; // 设置成为行内块元素
  color: rgba(255, 255, 255, 0);
}

.tab a:hover {
  background-color: #6771ff;
}

.tablinks_1 {
  border-radius: 50px 50px 0 0;
  background-color: #6771ff;
  width: 3px;
  height: 35px;
}

.tablinks_2 {
  margin: 5px 0;
  background-color: rgb(34, 32, 74);
  width: 3px;
  height: 35px;
}

.tablinks_3 {
  margin: 0 0 5px 0;
  background-color: rgb(34, 32, 74);
  width: 3px;
  height: 35px;
}

.tablinks_4 {
  border-radius: 0 0 50px 50px;
  background-color: rgb(34, 32, 74);
  width: 3px;
  height: 35px;
}

.vpn-intro {
  float: left;
  height: 500px;
  margin: 130px 0 0 -1220px;
  z-index: 1;
}

.wrapper {
  text-align: left;
  width: fit-content;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.title {
  font-size: 28px;
  font-weight: 600;
  background: repeating-linear-gradient(to right, #237fff, #75d5ff, #ed5aff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.subtitle {
  margin: 10px 0;
  font-size: 28px;
  font-weight: 600;
  color: #fff;
}

.description {
  margin: 40px 0;
  font-size: 14px;
  color: #fff;
}

.download-section {
  position: relative;
  align-items: center;
  display: inline-block;
}

.download_icon {
  position: absolute;
  left: 15%;
  top: 48%;
  transform: translateY(-50%);
  width: 13%;
  height: 49%;
  z-index: 2;
}

.download-link {
  position: absolute;
  text-align: center;
  line-height: 38px;
  top: 50%;
  left: 58%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 450;
}

.subscription-section {
  float: left;
  margin-top: 35%;
  margin-left: -98%;
}

.premium-features {
  height: 200px;
}

.speed-boost {
  display: inline-block;
  margin: 0 0 0 150px;
  width: 450px;
}

.expanded-nodes1 {
  display: inline-block;
  margin: 0 0 0 200px;
  width: 450px;
}

.expanded-nodes2 {
  display: inline-block;
  margin: 50px 0 0 150px;
  width: 450px;
}

.enhanced-privacy {
  display: inline-block;
  margin: 50px 0 0 200px;
  width: 450px;
}

.feature-title {
  // float: left;
  display: inline-block;
  padding-bottom: 20px;
  font-size: 28px;
  font-weight: bold;
  background: repeating-linear-gradient(to right, #fad62c, #e664ff, #4faeff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.feature-heading {
  padding-bottom: 10px;
  font-weight: normal;
  font-size: 19px;
  color: #fff;
}

.feature-description {
  font-size: 15px;
  color: #bfb0ff;
}

.pricing-background {
  background-image: url("@/assets/images/bg.jpg");
  background-size: 100% 100%;
  /* 图片将被拉伸以填充整个容器 */
  background-position: center top;
  /* 控制背景图片的位置，与上方盒子无缝拼接 */
  // background-color: rgb(49, 39, 65);
  width: 100%;
  // height: 100%;
  height: auto;
  /* 设置 div 的高度为整个视口高度 */
}

.subscription-options {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 400px;
}

.subscription_image {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  height: 250px;
}

.yearly-plan,
.monthly-plan,
.weekly-plan {
  float: left;
}

.year_img {
  width: 350px;
  height: 225px;
}

.month_img,
.week_img {
  // float: left;
  width: 100%;
}

//year
.button {
  /* 默认样式，右边只有边框的按钮 */
  background-color: transparent;
  color: #7732e2;
  /* 文字颜色 */
  border: 2px solid #7732e2;
  border-radius: 20px;
  // padding: 10px 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.button:hover {
  /* 悬停样式，最左边的黄色按钮 */
  background-color: orange;
  color: white;
  /* 文字颜色 */
  border: none;
}

.discount-badge {
  border-radius: 12px;
  color: white;
  margin-left: -355px;
  margin-top: -15px;
  width: 100px;
  height: 36px;
}

.discount-style {
  float: left;
  margin-left: -340px;
  margin-top: -8px;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
}

.discount-text-style {
  float: left;
  margin-left: -301px;
  margin-top: -8px;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
}

.plan-details-year {
  float: left;
  margin-top: 10px;
  margin-left: -350px;
}

.plan-details-year h2 {
  color: #703ebd;
  margin: 15px 0 0 90px;
  font-size: 25px;
}

.trial-info {
  color: #8465b6;
  padding: 0;
  margin: 0 0 0 110px;
  font-size: 16px;
  font-weight: normal;
}

.price_year {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-left: 20px;
}

#price {
  color: #290f50;
  font-size: 30px;
  font-weight: bold;
}

.currency-symbol-year,
.price-duration-year {
  color: #290f50;
  font-size: 20px;
  font-weight: bold;
}

.old-price {
  color: #8465b6;
  font-size: 12px;
  text-decoration: line-through;
}

.button_year {
  margin: 30px 0 0 25px;
  color: #7732e2;
  cursor: pointer;
  font-size: 20px;
  padding: 10px 20px;
  width: 300px;
  height: 45px;
}

.plan-details-month {
  float: left;
  margin-top: 10px;
  margin-left: -245px;
}

.plan-details-month h2 {
  color: #703ebd;
  font-size: 25px;
  margin: 20px 0 0 25px;
}

.price_month {
  margin: 50px 0 0 55px;
}

.current-month {
  color: #290f50;
  font-size: 16px;
  font-weight: bold;
}

.amount {
  color: #290f50;
  font-size: 30px;
  font-weight: bold;
}

.duration {
  color: #290f50;
  font-size: 20px;
  font-weight: bold;
}

.button_month {
  margin: 30px 0 0 25px;
  background: none;
  color: #7732e2;
  cursor: pointer;
  font-size: 20px;
  transition: background-color 0.3s, color 0.3s;
  width: 195px;
  height: 45px;
}

.plan-details-week {
  float: left;
  margin-top: 10px;
  margin-left: -245px;
}

.plan-details-week h2 {
  color: #703ebd;
  font-size: 25px;
  margin: 20px 0 0 35px;
}

.price_week {
  margin: 50px 0 0 60px;
}

.features-section {
  // float: left;
  height: 600px;
}

.comments-section {
  height: 800px;
}

.features-content {
  float: left;
  margin-top: 90px;
  margin-left: 150px;
  width: 500px;
}

.feature-image {
  margin-left: 60px;
  width: 600px;
  height: 600px;
}

.feature-title-part3 {
  // margin-top: 200px;
  // margin-left: 100px;
  font-size: 35px;
  font-weight: 600;
  background: repeating-linear-gradient(to right, #fad62c, #e664ff, #4faeff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.feature-item-part3 {
  margin: 70px 0 0;
  font-size: 22px;
  font-weight: 600;
  background: repeating-linear-gradient(to right, #6ba9ff, #bdfaff, #f6aaff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.feature-description-part3 {
  margin: 10px 0 0;
  font-size: 16px;
  font-weight: normal;
  color: #fff;
}

.feature-title2-part3 {
  margin: 40px 0 0;
  font-size: 22px;
  font-weight: 600;
  background: repeating-linear-gradient(to right, #6ba9ff, #bdfaff, #f6aaff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.feature-description2-part3 {
  margin: 10px 0 0;
  font-size: 16px;
  font-weight: normal;
  color: #fff;
}

.feature-title3-part3 {
  margin: 40px 0 0;
  font-size: 22px;
  font-weight: 600;
  background: repeating-linear-gradient(to right, #6ba9ff, #bdfaff, #f6aaff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.feature-description3-part3 {
  margin: 10px 0 0;
  font-size: 16px;
  font-weight: normal;
  color: #fff;
}

.security-section {
  // float: left;
  height: 600px;
}

.security-image {
  margin-left: 80px;
  width: 600px;
  height: 600px;
}

.security-content {
  width: 500px;
  float: left;
  margin-left: 800px;
  margin-top: -500px;
}

.feature-item-security {
  font-size: 22px;
  font-weight: 600;
  background: repeating-linear-gradient(to right, #6ba9ff, #bdfaff, #f6aaff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.feature-description-security {
  margin: 10px 0 0;
  font-size: 16px;
  font-weight: normal;
  color: #fff;
}

.feature-item2-security {
  margin: 40px 0 0;
  font-size: 22px;
  font-weight: 600;
  background: repeating-linear-gradient(to right, #6ba9ff, #bdfaff, #f6aaff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.feature-description2-part3 {
  margin: 10px 0 0;
  font-size: 16px;
  font-weight: normal;
  color: #fff;
}

.feature-title3-part3 {
  margin: 40px 0 0;
  background: repeating-linear-gradient(to right, #6ba9ff, #bdfaff, #f6aaff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.feature-description2-security {
  margin: 10px 0 0;
  font-size: 16px;
  font-weight: normal;
  color: #fff;
}

.feature-item3-security {
  font-size: 22px;
  font-weight: 600;
  margin: 40px 0 0;
  background: repeating-linear-gradient(to right, #6ba9ff, #bdfaff, #f6aaff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.feature-description3-security {
  margin: 10px 0 0;
  font-size: 16px;
  font-weight: normal;
  color: #fff;
}

.feature-item4-security {
  font-size: 22px;
  font-weight: 600;
  margin: 40px 0 0;
  background: repeating-linear-gradient(to right, #6ba9ff, #bdfaff, #f6aaff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.feature-description4-security {
  margin: 10px 0 0;
  font-size: 16px;
  font-weight: normal;
  color: #fff;
}

.comments-background {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-size: cover;
  float: left;
  width: 100%;
  height: 600px;
}

.comments-content {
  float: left;
}

.comments-title {
  display: inline-block;
  margin: 70px 0 0 -1250px;
  color: #fff;
  font-size: 25px;
  font-weight: 600;
  z-index: 2;
}

.rectangle {
  position: relative;
  top: 20%;
  border-radius: 0 90px 30px 30px;
  width: 300px;
  height: 200px;
  background-color: #fff;
}

.rectangle img {
  position: absolute;
  top: 75%;
  left: 8%;
  width: 170px;
  height: 25px;
}

.user-icon {
  position: absolute;
  top: 5%;
  border-radius: 50px;
  width: 100px;
  height: 100px;
  background-color: #fff;
  z-index: 1;
}

.icon-circle1 {
  position: absolute;
  top: 10%;
  left: 10%;
  border-radius: 50px;
  width: 80px;
  height: 80px;
  background-color: rgb(214, 138, 52);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.icon-circle2 {
  position: relative;
  position: absolute;
  top: 10%;
  left: 10%;
  border-radius: 50px;
  width: 80px;
  height: 80px;
  background-color: rgb(98, 198, 133);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.icon-circle3 {
  position: absolute;
  top: 10%;
  left: 10%;
  border-radius: 50px;
  width: 80px;
  height: 80px;
  background-color: rgb(226, 105, 137);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

h3 {
  margin: 0;
  font-size: 60px;
  color: #fff;
}

p {
  padding-top: 25px;
  padding-left: 110px;
  font-size: 17px;
  font-weight: bold;
}

.user-comment1 {
  float: left;
  height: 350px;
  margin: 150px 0 0 -1100px;
  transform: translateX(-50%);
  z-index: 1;
}

.user-comment2 {
  float: left;
  height: 350px;
  margin: 150px 0 0 -700px;
  transform: translateX(-50%);
}

.user-comment3 {
  float: left;
  height: 350px;
  margin: 150px 0 0 -300px;
  transform: translateX(-50%);
}

.comment-text {
  padding: 0 25px 0 25px;
  font-size: 16px;
}

.app-end-nav {
  justify-content: center;
  float: left;
  margin: 0 480px;
  font-size: 16px;
}

.app-end-nav a {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #7a72c7;
}
</style>
