import { Router } from "vue-router";

export default class RouterUtils {
  private router: Router;

  private isMobile: boolean;

  constructor(router: Router) {
    this.router = router;
    this.isMobile = window.innerWidth <= 748;
  }

  public jumpToRegister() {
    const path = !this.isMobile ? "/account/register" : "/mobile/register";
    this.router.push({
      path,
    });
  }

  public jumpToSubscribe() {
    this.router.push({
      path: "/subscribe",
    });
  }

  public jumpToLoginPage() {
    const path = !this.isMobile ? "/account/login" : "/mobile/login";
    this.router.push({
      path,
    });
  }

  public jumpToMainPage() {
    this.router.push("/");
  }
}
