<template>
  <!-- <AppTopNavMobile /> -->
  <div class="appMobile">
    <div class="interactive">
      <a v-if="isLoggedIn" class="interactive-link" @click="togglePopup" @keydown.enter.space="togglePopup"
        tabindex="0">
        <span class="avatar-circle">{{ userInitial }}</span>
      </a>
    </div>
    <div v-if="showPopup" class="popup">
      <div class="popup-content">
        <div class="close" @click="togglePopup" @keydown.enter.space="togglePopup" tabindex="0"></div>
        <div class="popup-header">
          <div class="user-circle">
            <h3>{{ userInitial }}</h3>
          </div>
          <p class="user-email">{{ userEmail }}</p>
        </div>
        <div class="popup-body">
          <div class="popup-item">
            <span>Connection mode:</span>
            <span>{{ connectionMode }}</span>
          </div>
          <div class="popup-item">
            <span>Devices:</span>
            <span>1/1 Devices</span>
          </div>
          <div class="popup-item">
            <span>Valid until:</span>
            <span>{{ validUntil }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="app-header-nav" id="part_1">
      <div class="header-content" id="part_2">
        <span class="wrapper app-title">FREEGUARD VPN</span>
        <span class="wrapper app-subtitle">Unlimited Fast & Secure Proxy</span>
        <span class="wrapper app-description">
          Protect your online privacy with fast <br />and secure free VPN proxy</span>
      </div>
    </div>
    <!-- 可拉伸背景图 -->
    <div class="background-main">
      <div class="features-section">
        <div class="features-content">
          <span class="wrapper features-title" id="part_3">FEATURES INTRO</span>
          <span class="wrapper feature-item">Protect privacy</span>
          <span class="wrapper feature-description">
            Help you protect your online privacy, especially when connecting to public
            Wi-Fi networks.</span>
          <span class="wrapper feature-item2">Bypass geographic restrictions</span>
          <span class="wrapper feature-description2">Allow you to bypass geographic restrictions and access content and
            websites
            that may be blocked in your respective regions.</span>
          <span class="wrapper feature-item3">Improve network speed</span>
          <span class="wrapper feature-description3">Some of you may need improve network speeds, especially when your
            internet
            connections are slow. That’s exactly what FreeGuard VPN is capable of.</span>
        </div>
        <img src="@/assets/images/image1.png" alt="" class="features-image" />
      </div>
      <div class="security-section">
        <div class="security-content">
          <span class="wrapper security-item">Improve online security</span>
          <span class="wrapper security-description">Help you protect your online privacy, especially when connecting to
            public
            Wi-Fi networks.</span>
          <span class="wrapper security-item2">Download and share files</span>
          <span class="wrapper security-description2">Can allow you to download and share files anonymously, especially
            when using
            P2P sharing.</span>
          <span class="wrapper security-item3">Access international services</span>
          <span class="wrapper security-description3">
            Can enable you to access international streaming services and websites, such
            as Netflix and Hulu.</span>
          <span class="wrapper security-item4" id="part_2">Bypass website censorship</span>
          <span class="wrapper security-description4" id="part_3">Can help you from countries bypass website
            censorship.</span>
        </div>
        <img src="@/assets/images/image2.png" alt="" class="security-image" />
      </div>
      <div class="comments-section">
        <div class="comments-background">
          <span class="comments-title" id="part_4">USER COMMENTS</span>
          <div class="user-comment1">
            <div class="circle">
              <div class="icon-circle1">
                <h3>C</h3>
              </div>
            </div>
            <div class="rectangle_1">
              <p class="user-name">Chloe ***</p>
              <div class="comment-text comment-text-first">
                This app is pretty useful and stable. I would like to recommend it to my
                family and friends. Please keep going to improve it!
              </div>
              <img src="@/assets/images/star_1.png" alt="" class="star_1" />
            </div>
          </div>
          <div class="user-comment2">
            <div class="circle">
              <div class="icon-circle2">
                <h3>M</h3>
              </div>
            </div>
            <div class="rectangle_2">
              <p class="user-name">Mohammad ***</p>
              <div class="comment-text">Best apps. Very easy to connect.</div>
              <img src="@/assets/images/star_1.png" alt="" class="star_1" />
            </div>
          </div>
          <div class="user-comment3">
            <div class="circle">
              <div class="icon-circle3">
                <h3>G</h3>
              </div>
            </div>
            <div class="rectangle_3">
              <p class="user-name">Gregory ***</p>
              <div class="comment-text">
                It works I can for sure tell you that much it does get the job done
              </div>
              <img src="@/assets/images/star_1.png" alt="" class="star_1" />
            </div>
          </div>
          <img src="@/assets/images/bg2.png" alt="" class="bg2" />
        </div>
        <!-- 吸底下载键 -->
        <a v-if="isLoggedIn" :href="downloadUrl">
          <div class="image_bottom">
            <div class="login">
              <img src="@/assets/images/download_icon.png" alt="" class="download_icon" />
              <span class="login-text">Downloads</span>
            </div>
          </div>
        </a>
        <router-link v-else to="mobile/login">
          <div class="image_bottom">
            <div class="login">
              <span class="login-text">Login</span>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, onBeforeUnmount, ref, computed } from "vue";
import "@/assets/style/base_mobile.less";
import { useUserStore } from "@/store/user";
import urlUtils from "@/utils/urlUtils";

const showPopup = ref(false);

const userStore = useUserStore();

const userEmail = computed(() => userStore.user.email);
const isLoggedIn = computed(() => !!userEmail.value);
const isVip = computed(() => !!userStore.user.isVip);
const connectionMode = computed(() => (isVip.value ? "vip" : "free"));
const validUntil = computed(() => userStore.user.endDate || "");

const downloadUrl = ref(urlUtils.getDownloadUrl());

function handleScroll() {
  if (window.scrollY <= 0) {
    window.scrollTo(0, 0);
  }
}

const userInitial = computed(() => (userEmail.value ? userEmail.value.charAt(0).toUpperCase() : ""));

function togglePopup() {
  showPopup.value = !showPopup.value;
}

onMounted(() => {
  window.addEventListener("scroll", handleScroll);
});

onBeforeUnmount(() => {
  window.removeEventListener("scroll", handleScroll);
});
</script>

<style lang="less" scoped>
.appMobile {
  margin-top: 60px;
  width: 100%;
}

.interactive-link {
  position: fixed;
  right: 0;
  top: 0;
  width: 25vw;
  height: 7.2vh;
  background: linear-gradient(to right, rgb(0 0 0 / 0%), #1f1535a1, #1f1535);
  z-index: 3;
}

.avatar-circle {
  margin-top: 2vh;
  margin-left: 12vw;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8vw;
  height: 8vw;
  background-color: #4caf50;
  color: white;
  font-size: 5vw;
  border-radius: 50%;
  border: 2px solid white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  position: relative;
  overflow: hidden;
  z-index: 4;
}

.app-header-nav {
  display: flex;
  justify-content: space-between;
  background-image: url("@/assets/images/mobile/brackground-mobile.jpg");
  background-size: cover;
  padding-bottom: 8vh;
}

.header-content {
  float: left;
  margin-top: 10vw;
  margin-left: 20px;
}

.wrapper {
  text-align: left;
  width: fit-content;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.app-title {
  font-size: 4.3vw;
  font-weight: 600;
  background: repeating-linear-gradient(to right, #237fff, #75d5ff, #ed5aff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.app-subtitle {
  margin: 10px 0;
  font-size: 3.5vw;
  font-weight: 600;
  color: #fff;
}

.app-description {
  margin: 20px 0;
  font-size: 2.5vw;
  font-weight: 450;
  color: #e1e1e1;
}

.comments-section {
  height: 165vw;
}

// 可拉伸背景图
.background-main {
  background-image: url("@/assets/images/mobile/brackground-stretch.jpg");
  background-size: 100% 100%;
  /* 图片将被拉伸以填充整个容器 */
  background-position: center top;
  /* 控制背景图片的位置，与上方盒子无缝拼接 */
  width: 100%;
  height: 100%;
  /* 设置 div 的高度为整个视口高度 */
}

.features-image {
  object-fit: contain;
  width: 100vw;
}

.features-content {
  padding: 0 5vw;
}

.features-title {
  font-size: 4.3vw;
  font-weight: 600;
  background: repeating-linear-gradient(to right, #fad62c, #e664ff, #4faeff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.feature-item {
  margin: 40px 0 0;
  font-size: 3.5vw;
  font-weight: 600;
  background: repeating-linear-gradient(to right, #6ba9ff, #bdfaff, #f6aaff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.feature-description {
  margin: 10px 0 0;
  font-size: 2.8vw;
  font-weight: 300;
  color: #fff;
}

.feature-item2 {
  margin: 40px 0 0;
  font-size: 3.5vw;
  font-weight: 600;
  background: repeating-linear-gradient(to right, #6ba9ff, #bdfaff, #f6aaff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.feature-description2 {
  margin: 10px 0 0;
  font-size: 2.8vw;
  font-weight: normal;
  color: #fff;
}

.feature-item3 {
  margin: 40px 0 0;
  font-size: 3.5vw;
  font-weight: 600;
  background: repeating-linear-gradient(to right, #6ba9ff, #bdfaff, #f6aaff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.feature-description3 {
  margin: 10px 0 0;
  font-size: 2.8vw;
  font-weight: normal;
  color: #fff;
}

// iphone_2
.security-image {
  margin: 2vh 0 2vh 0;
  width: 100vw;
}

.security-content {
  padding: 0 5vw;
}

.security-item {
  font-size: 3.5vw;
  font-weight: 600;
  background: repeating-linear-gradient(to right, #6ba9ff, #bdfaff, #f6aaff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.security-description {
  margin: 10px 0 0;
  font-size: 2.8vw;
  font-weight: normal;
  color: #fff;
}

.security-item2 {
  font-size: 3.5vw;
  font-weight: 600;
  margin: 40px 0 0;
  background: repeating-linear-gradient(to right, #6ba9ff, #bdfaff, #f6aaff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.security-description2 {
  margin: 10px 0 0;
  font-size: 2.8vw;
  font-weight: normal;
  color: #fff;
}

.security-item3 {
  font-size: 3.5vw;
  font-weight: 600;
  margin: 40px 0 0;
  background: repeating-linear-gradient(to right, #6ba9ff, #bdfaff, #f6aaff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.security-description3 {
  margin: 10px 0 0;
  font-size: 2.8vw;
  font-weight: normal;
  color: #fff;
}

.security-item4 {
  font-size: 3.5vw;
  font-weight: 600;
  margin: 40px 0 0;
  background: repeating-linear-gradient(to right, #6ba9ff, #bdfaff, #f6aaff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.security-description4 {
  margin: 10px 0 0;
  font-size: 2.8vw;
  font-weight: normal;
  color: #fff;
}

// 评论区
.comments-background {
  width: 100vw;
  height: 13vw;
}

.bg2 {
  width: 100%;
}

.rectangle_1 {
  position: relative;
  border-radius: 0 5vw 5vw 5vw;
  width: 90vw;
  height: 28vw;
  background-color: #fff;
}

.rectangle_2 {
  position: relative;
  border-radius: 0 5vw 5vw 5vw;
  width: 90vw;
  height: 28vw;
  background-color: #fff;
}

.rectangle_3 {
  position: relative;
  border-radius: 0 5vw 5vw 5vw;
  width: 90vw;
  height: 28vw;
  background-color: #fff;
}

.circle {
  position: absolute;
  // top: -29%;
  border-radius: 5vh;
  width: 17vw;
  height: 17vw;
  background: #fff;
  z-index: 1;
}

.icon-circle1 {
  position: absolute;
  top: 8%;
  left: 9%;
  border-radius: 100px;
  width: 14vw;
  height: 14vw;
  background-color: rgb(214, 138, 52);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.icon-circle2 {
  position: relative;
  position: absolute;
  top: 8%;
  left: 9%;
  border-radius: 100px;
  width: 14vw;
  height: 14vw;
  background-color: rgb(98, 198, 133);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.icon-circle3 {
  position: absolute;
  top: 8%;
  left: 9%;
  border-radius: 100px;
  width: 14vw;
  height: 14vw;
  background-color: rgb(226, 105, 137);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

h3 {
  margin: 0;
  font-size: 10vw;
  color: #fff;
}

// p {
//   padding-top: 3vw;
//   font-size: 4vw;
//   font-weight: bold;
// }

.user-name {
  padding-top: 3vw;
  font-size: 4vw;
  font-weight: bold;
  margin: 4vh 0 0 18vw;
}

.star_1 {
  position: absolute;
  top: 10%;
  left: 60%;
  // width: 214px;
  width: 30vw;
  // height: 33px;
  z-index: 0;
}

.user-comment1 {
  position: absolute;
  margin: 12vw 0 0 0;
  left: 5%;
}

.user-comment2 {
  position: absolute;
  margin: 55vw 0 0 0;
  left: 5vw;
}

.user-comment3 {
  position: absolute;
  margin: 95vw 0 0 0;
  left: 5vw;
}

.comments-title {
  position: absolute;
  padding: 6vw 5vw;
  color: #fff;
  font-size: 4vw;
  font-weight: 600;
  z-index: 2;
}

.comment-text {
  padding: 5vw 7vw 0 7vw;
  font-size: 3.5vw;
  z-index: 3;
}

.comment-text-first {
  padding: 2vw 7vw 0 7vw !important;
}

.image_bottom {
  background-image: url("@/assets/images/image_bottom.png");
  background-size: 100% 100%;
  /* 图片将被拉伸以填充整个容器 */
  background-position: center top;
  width: 100%;
  position: fixed;
  left: 50%;
  transform: translate(-50%);
  bottom: 0;
  z-index: 2;
}

.login {
  position: relative;
  background-image: url("@/assets/images/login_image/login-mobile.png");
  background-size: 100% 100%;
  /* 图片将被拉伸以填充整个容器 */
  background-position: center top;
  /* 控制背景图片的位置，与上方盒子无缝拼接 */
  // width: 707px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  width: 95vw;
  height: 25vw;
  left: 50%;
  transform: translate(-50%);
  bottom: 10px;
  z-index: 3;
}

.download_icon {
  width: 6vw;
  z-index: 2;
}

.login-text {
  margin-left: 2vw;
  font-size: 5vw;
  color: #fff;
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: url(@/assets/images/personal_msg.png) no-repeat;
  background-size: 100% auto;
  width: 90%;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.popup-content {
  padding: 20px;
  height: auto;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: url("@/assets/images/close_buttom.png") no-repeat;
  background-size: contain;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.popup-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.user-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  background: url("@/assets/images/profile_photo.png") no-repeat;
  background-size: cover;
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.user-circle h3 {
  font-size: 38px;
  color: white;
}

.popup-body {
  margin-top: 20px;
  width: 100%;
}

.popup-item {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
}

p {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
}
</style>
