import { STATUS_CODE } from "@/consts/network";
import { BaseResp } from "@/typings/api/baseServices";
import { openErrModal } from "./ui";

export const isApiWorkWell = (resp: BaseResp<any>) => {
  if (resp.code === STATUS_CODE.SUCCESS) {
    return true;
  }
  return false;
};

export const showErrMsg = (resp: BaseResp<any>) => {
  const errMsg = resp.msg || "Network error, please retry.";
  openErrModal(errMsg);
};
