<template>
  <CenterWrap>
    <!-- 覆盖 title 插槽，不显示任何内容 -->
    <template v-slot:title>
      <div>FREEGUARD VPN</div>
    </template>
    <!-- 覆盖 content 插槽，不显示任何内容 -->
    <template v-slot:content>
      <div>Unlimited Fast & Secure Proxy</div>
      <div>Protect your online privacy with fast and secure free VPN proxy</div>
    </template>
  </CenterWrap>
  <!-- 自定义内容 -->
  <div class="subscription">
    <img src="@/assets/images/successful_image.png" alt="Successful" class="subscription__image">
    <div class="subscription__message">Payment successful!</div>
    <div class="subscription__information">
      <div class="subscription__item">
        <span class="subscription__label">Purchased package:</span>
        <span class="subscription__value">Yearly Plan</span>
      </div>
      <div class="subscription__item">
        <span class="subscription__label">Paid already:</span>
        <span class="subscription__value">$199.99</span>
      </div>
      <div class="subscription__item">
        <span class="subscription__label">Effective Date:</span>
        <span class="subscription__value">2024/12/8 - 2025/12/8</span>
      </div>
    </div>
  </div>

  <div class="functional-information-wrapper">
    <div class="functional-information">
      <div class="functional-information__title">Upgrade To Premium</div>
      <div class="functional-information__feature">
        <span class="feature__name">1. Speed Boost</span>
        <span class="feature__description">Experience over 4x faster speeds, depending on local network
          conditions.</span>
      </div>
      <div class="functional-information__feature">
        <span class="feature__name">2. Expanded Nodes</span>
        <span class="feature__description">Access a wider range of geographic locations.</span>
      </div>
      <div class="functional-information__feature">
        <span class="feature__name">3. Enhanced Privacy</span>
        <span class="feature__description">Leverage virtual network card technology and proprietary protocols for
          superior traffic camouflage and privacy.</span>
      </div>
      <div class="functional-information__feature">
        <span class="feature__name">4. Broader App Support</span>
        <span class="feature__description">Enjoy a richer selection of apps and websites, including ChatGPT, Netflix,
          and more.</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
</script>

<style scoped>
.subscription {
  margin-top: -2vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #180B38;
}

.subscription__image {
  width: 7vw;
}

.subscription__message {
  margin: 2vh 0;
  font-size: 1.3vw;
  font-weight: bold;
}

.subscription__information {
  display: flex;
  flex-direction: column;
  /* 间距 */
  gap: 12px;
  width: 25vw;
}

.subscription__label {
  color: #9790A8;
}

.subscription__item {
  display: flex;
  justify-content: space-between;
}

.functional-information-wrapper {
  margin-top: 3vh;
  background-color: #E5E7F4;
  border-radius: 1vw;
}

.functional-information {
  display: flex;
  align-items: center;
  flex-direction: column;
  /* 间距 */
  gap: 12px;
  padding: 1vw;
}

.functional-information__title {
  margin-top: -1vh;
  font-weight: bold;
  color: #180B38;
}

.feature__name,
.feature__description {
  display: inline-block;
  font-size: 0.8vw;
  color: #180B38;
}

.feature__name {
  font-weight: bold;
}
</style>
