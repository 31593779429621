<template>
  <div class="wrap">
    <CenterWrap :class="{ 'subscribe-style': isSubscribePage }">
      <template v-slot:title>
        <span v-if="isSubscribePage" class="title">FREEGUARD VPN</span>
        <span v-else>FREEGUARD VPN</span>
      </template>
      <template v-slot:content>
        <div v-if="isSubscribePage" class="content-1">Unlimited Fast & Secure Proxy</div>
        <div v-else>Unlimited fast & secure proxy Protect your online privacy with fast and secure free
          VPN proxy</div>
        <div v-if="isSubscribePage" class="content-2">Protect your online privacy with fast and secure free VPN
          proxy</div>
        <div v-if="isSubscribePage" class="download-section">
          <img src="@/assets/images/subscribe_download.png" alt="" class="download_image">
          <img src="@/assets/images/download_icon.png" alt="" class="download_icon">
          <a href="https://play.google.com/store/apps/details?id=com.freeguardvpn.app"
            class="download-link">Downloads</a>
        </div>
      </template>
    </CenterWrap>
  </div>
</template>
<script lang="ts" setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import CenterWrap from './CenterWrap.vue';

const route = useRoute();

const isSubscribePage = computed(() => route.name === 'subscribe');

</script>
<style lang="less" scoped>
.wrap {
  background-color: #743CCC;
  width: 100%;
  height: 100%;
}

.title {
  background: repeating-linear-gradient(to right, #237fff, #75d5ff, #ed5aff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.content-2 {
  margin-top: 3vh;
  margin-left: 3vw;
  // white-space: normal;
  /* 确保文本可以正常换行 */
  // word-wrap: break-word;
  /* 长单词换行 */
  word-break: break-word;
  /* 支持更多浏览器 */
}

::v-deep(.subscribe-style .title) {
  // margin-top: -15vh !important;
  margin-left: -4.7vw !important;
}

::v-deep(.subscribe-style .content) {
  margin-top: 0.5vw !important;
}

::v-deep(.subscribe-style .content-1) {
  margin-left: 2vw !important;
  font-size: 3vh !important;
  font-weight: bold !important;
}

::v-deep(.subscribe-style .content-2) {
  margin-left: 2vw !important;
  width: 23vw;
  margin-right: 0.5vw !important;
}

::v-deep(.subscribe-style .center-wrap) {
  width: 60vw !important;
}

::v-deep(.subscribe-style .info-wrap) {
  padding: 2vw !important;
}

.download-section {
  margin-top: 10vh;
  margin-left: 3.3vw;
  position: relative;
  align-items: center;
  display: inline-block;
}

.download_image {
  width: 20vw;
  height: 6vh;
}

.download_icon {
  position: absolute;
  left: 28%;
  top: 47%;
  transform: translateY(-50%);
  width: 8%;
  height: 49%;
  z-index: 2;
}

.download-link {
  position: absolute;
  text-align: center;
  line-height: 38px;
  top: 47%;
  left: 57%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 450;
}
</style>
