<template>
  <div class="wrap">
    <div class="header-container">
      <div class="header">
        <router-link to="/">
          <img
            v-if="isMobileLoginPage"
            src="@/assets/images/return_key.png"
            alt=""
            class="return_key"
          />
        </router-link>
        <div class="header-title">FREEGUARD VPN</div>
        <div class="header-subtitle">Unlimited Fast & Secure Proxy</div>
      </div>
    </div>
    <CenterWrap> </CenterWrap>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import CenterWrap from "./CenterWrap-mobile.vue";

// 获取当前路径
const currentPath = window.location.pathname;

// 判断是否为 /mobile/login 页面
const isMobileLoginPage = computed(() => currentPath === "/mobile/login");
</script>

<style lang="less" scoped>
.wrap {
  background-color: #743ccc;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.header-container {
  margin: 0 auto;
}

.header {
  margin: 12vh 0 0 0;
  // position: fixed;
  /* 保持位置不变 */
  // top: 12vh;
  // left: 17vw;
  text-align: center;
}

.return_key {
  display: inline-block;
  width: 8vw;
  height: 8vw;
  margin-left: -80vw;
}

.header-title {
  color: #fff;
  font-size: 3vh;
  /* 使用相对单位 */
  font-weight: bold;
  font-family: Helvetica, Helvetica;
  z-index: 100;
}

.header-subtitle {
  margin-left: 0.4vw;
  color: #bc91ff;
  font-weight: bold;
  font-family: Helvetica, Helvetica;
  font-size: 1.8vh;
}
</style>
