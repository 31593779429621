import { getMobileOS } from ".";

class UrlUtils {
  private isMobile: boolean;

  private mobileType = "";

  constructor() {
    this.isMobile = window.innerWidth <= 748;
    this.mobileType = getMobileOS(navigator.userAgent);
  }

  getLoginUrl() {
    const url = window.location.href;
    const urlObj = new URL(url);
    const path = this.isMobile ? "/mobile/login" : "/account/login";
    return `${urlObj.origin}${path}`;
  }

  getSetPasswordUrl() {
    const url = window.location.href;
    const urlObj = new URL(url);
    const path = this.isMobile ? "/mobile/setpassword" : "/account/password";
    return `${urlObj.origin}${path}`;
  }

  getDownloadUrl() {
    return this.mobileType === "iOS" ? "https://apps.apple.com/us/app/freeguard-vpn/id6622284235" : "https://play.google.com/store/apps/details?id=com.freeguardvpn.app";
  }
}

export default new UrlUtils();
