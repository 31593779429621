import { defineComponent as _defineComponent } from 'vue'
import { ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ContactUs',
  setup(__props, { expose: __expose }) {
  __expose();

const hrefContent = ref('mailto:support@planetlinkinc.com?subject=Customer%20Service');


const __returned__ = { hrefContent }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})